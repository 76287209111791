import axios from "axios";
import { CamGameDataDTO, GameSetting, GameSettingDTO, CamScheduleDTO } from "../types/CamTypes";

const SERVICES_URL = window.MLBBest.envVariables.SERVICES_URL;
const API_URL = `${SERVICES_URL}/SF`;

export const getSFGameSetting = (date: string, teamId: string, gamePk: string): Promise<CamGameDataDTO> =>
  axios.get(`${API_URL}/series/${date}/${teamId}/${gamePk}`).then(response => response.data);

export const getGamesFor7Days = (date: string): Promise<Array<CamScheduleDTO>> =>
  axios.get(`${API_URL}/dashboard/${date}`).then(response => response.data);

export const copySFGameSetting = (fromGamePk: number, toGamePk: number): Promise<GameSettingDTO> =>
  axios.put(`${API_URL}/copy/${fromGamePk}/to/${toGamePk}`).then(response => response.data);

export const setDefaultSFGameAngleSetting = (gamePk: number): Promise<GameSettingDTO> =>
  axios.put(`${API_URL}/default/${gamePk}`).then(response => response.data);

export const clearSFGameAngleSettings = (gamePk: number): Promise<GameSettingDTO> =>
  axios.put(`${API_URL}/clear/${gamePk}`).then(response => response.data);

export const saveSFGameSetting = (data: GameSetting): Promise<GameSetting> =>
  axios.put(`${API_URL}/save`, data).then(response => response.data);
