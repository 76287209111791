import React, {
  createContext,
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { OnScrollParams } from "react-virtualized/dist/es/ScrollSync";
import { useBreakpoints, useTheme, useWindowDimensions } from "../../../hooks";
import {
  ActionProps,
  CustomMobileAccordionType,
  DataTableColumn,
  DataTableDataType,
  DatatableThemeType,
  DataTableType,
  ExpansionType,
  RowClick,
  RowSelection,
  RowSelectionV2,
  SortDirection,
} from "../../../types";
import { debounce, deepClone, deepMerge, getElement, uniqueStr } from "../../../utils";
import { PaginationProps, PaginationType } from "../../pagination";
import { DataTableInfiniteLoaderConfig } from "./common/DataTableInfiniteLoader";
import {
  DataTableExpansion,
  DataTableProps,
  IsExpandedType,
  RowStylesGetterType,
  SubRowStylesGetterType,
} from "./types/DataTableComponentTypes";
import { calculateCellSize } from "./utils/TableUtils";

interface DataTableContextState<T extends DataTableDataType> {
  type: DataTableType;
  tableId: string;
  rowPrefix: string;
  initialized: boolean;
  className?: string;
  loading: boolean;
  data: T[];
  columns: DataTableColumn<T>[];
  columnSizes: number[];
  rowStylesGetter: RowStylesGetterType<T>;
  statusTextRender: () => string | JSX.Element;
  rowDisabledKey: keyof T;
  actions?: ActionProps<T>;
  accordion: {
    accordion?: React.FC<React.PropsWithChildren<CustomMobileAccordionType<T>>>;
    accordionBreakpoint: boolean;
  };
  pagination: {
    pagination?: PaginationType;
    paginationProps?: Omit<PaginationProps, "onValueChanged" | "pagination">;
    onPaginationChanged: () => void;
    setOnPaginationChanged: (value: () => void) => void;
  };
  heights: {
    noScroll: boolean;
    bodyHeight: number;
    rowHeight: number;
    headerHeight: number;
    loadingHeight: number;
  };
  widths: {
    tableWidth: number;
  };
  rowClick?: RowClick<T>;
  sort: {
    column?: string;
    direction?: SortDirection;
    sortFunction: (key: keyof T, direction: SortDirection) => void;
  };
  addRows: {
    shouldAddRows: boolean;
    addRowFunction?: () => void;
  };
  expansion: {
    expandable: boolean;
    expandedKey: keyof T;
    expandedRows: DataTableExpansion<T>;
    expandAll: (value: boolean) => void;
    expansionElement?: React.FC<React.PropsWithChildren<ExpansionType<T>>>;
    expandCallback: (index: number, value: T, expanded: boolean) => void;
    displayExpandAllButton: boolean;
    displayExpansionButton: boolean;
    anyAreExpanded: boolean;
    tableExpanded: (index: number, newState: boolean, rowValue: T) => void;
  };
  selection: {
    allRowsSelected: boolean;
    isSelectionDisabled: (index: number) => boolean;
    isRowSelected: (index: number) => boolean;
    onSelectionChange: (rowIndexes: number[], selected: boolean) => void;
    selectAllRows: (value: boolean) => void;
    showSelectAll?: boolean;
    showCheckbox: boolean;
  };
  subRows: {
    hasSubRows: boolean;
    subRowStylesGetter?: SubRowStylesGetterType<T>;
  };
  scroll: {
    showScrollDown?: boolean;
    scrollDownTable: () => void;
    scrollToIndex?: number;
    onScroll?: (params: OnScrollParams) => void;
    onTableScroll: (params: OnScrollParams) => void;
    hasHorizontalScroll: boolean;
  };
  noDataMessage: string;
  infiniteLoader?: DataTableInfiniteLoaderConfig;
  styles: DatatableThemeType;
  setBCRTableRef: (value: RefObject<HTMLDivElement> | null) => void;
  setBCRTableBodyRef: (value: RefObject<HTMLDivElement> | null) => void;
}

const DataTableContext = createContext<DataTableContextState<any> | undefined>(undefined);

const sum = (accumulator: number, currentValue: number) => accumulator + currentValue;

const alwaysFalse = () => false;
const noop = () => {};

type DataTableProviderProps<T extends DataTableDataType> = {
  children: React.ReactNode;
  tableProps: DataTableProps<T>;
};

const DataTableProvider = <T extends DataTableDataType>({ children, tableProps }: DataTableProviderProps<T>) => {
  const { isMobile, isTabletOrMobile, isDesktop } = useBreakpoints();

  const [tableId] = useState<string>(`bcr-table-${uniqueStr()}`);
  const [rowPrefix] = useState<string>(`bcr-row-${uniqueStr()}`);
  const [initialized, setInitialized] = useState<boolean>(false);

  const [localOnPaginationChanged, setLocalOnPaginationChanged] = useState<() => void | undefined>();

  const {
    data = [],
    actions,
    columns = [],
    expansion,
    tableHeights = {
      noScroll: false,
      headerHeight: 25,
      maxTableHeight: 0,
      rowHeight: 40,
      autogrow: false,
      maxHeightOffset: 400,
      parentHeight: false,
    },
    sortColumn,
    sortDirection,
    sortFunction = (_key: keyof T, _direction: SortDirection) => {},
    rowStylesGetter = () => {},
    minColumnWidth = 100,
    accordion,
    accordionBreakpoint = "mobile",
    virtualized = true,
    displayExpansionButton = true,
    displayExpandAllButton = true,
    scrollToIndex: scrollToIndexProp,
    subRowStylesGetter,
    rowSelection,
    rowDisabledKey = "",
    selectAll = true,
    rowExpansion = {
      expandCallback: () => {},
      expandAllCallback: () => {},
      expandable: false,
      expandedKey: "expansion",
    },
    rowClick,
    styles = {},
    className = "",
    statusTextOverride = null,
    addRow = false,
    addRowFunction = () => {},
    pagination,
    loading = false,
    noDataMessage = "",
    onScroll,
    infiniteLoader,
    paginationProps = {},
    showScrollDown,
  } = tableProps;

  const [BCRTableRef, setBCRTableRef] = useState<RefObject<HTMLDivElement> | null>(null);
  const [BCRTableBodyRef, setBCRTableBodyRef] = useState<RefObject<HTMLDivElement> | null>(null);
  const [copiedData, setCopiedData] = useState<T[]>([]);
  const rowHeight: number = tableHeights.rowHeight !== undefined ? tableHeights.rowHeight : 40;
  const headerHeight: number = tableHeights.headerHeight !== undefined ? tableHeights.headerHeight : 25;
  const maxHeightOffset: number = tableHeights.maxHeightOffset !== undefined ? tableHeights.maxHeightOffset : 400;
  const maxTableHeight: number = tableHeights.maxTableHeight !== undefined ? tableHeights.maxTableHeight : 0;
  const autogrow: boolean = tableHeights.autogrow || false;
  const parentHeight: boolean = tableHeights.parentHeight || false;
  const { Theme } = useTheme();
  const StylesOverride: DatatableThemeType = deepMerge<DatatableThemeType>(Theme.datatable, styles);
  const [lastIndex, setLastIndex] = useState<number | undefined>();
  const [BodyHeight, setBodyHeight] = useState(0);
  const [expandedRows, setExpandedRows] = useState<DataTableExpansion<T>>({});
  const [tableSubRows, setTableSubRows] = useState(false);
  const [anyAreExpanded, setAnyAreExpanded] = useState(false);
  const [allRowsSelected, setAllRowsSelected] = useState(false);
  const [tableWidth, setTableWidth] = useState(400);
  const [addtlColumnWidths, setAddtlColumnWidths] = useState(400);
  const [finalColumns, setFinalColumns] = useState<DataTableColumn<T>[]>([]);
  const [columnSizes, setColumnSizes] = useState<number[]>([]);
  const [horizontalScrollHeight, setHorizontalScrollHeight] = useState<number>(0);
  const [scrollToIndex, setScrollToIndex] = useState<undefined | number>(scrollToIndexProp);
  const { height } = useWindowDimensions();
  const [shouldShowScrollDown, setShouldShowScrollDown] = useState<boolean>(false);
  const [onScrollParams, setOnScrollParams] = useState<OnScrollParams | undefined>(undefined);
  const [hasHorizontalScroll, setHasHorizontalScroll] = useState<boolean>(false);

  const actionsV2: ActionProps<T> | undefined = useMemo<ActionProps<T> | undefined>(() => {
    if (!!actions) {
      if (Array.isArray(actions)) {
        return {
          singleOpen: true,
          actions,
          location: "right",
          icon: "fa-ellipsis-v",
        } as ActionProps<T>;
      } else {
        return actions as ActionProps<T>;
      }
    } else {
      return undefined;
    }
  }, [actions]);

  const rowSelectionV2 = useMemo<RowSelectionV2 | undefined>(() => {
    if (rowSelection?.hasOwnProperty("isSelected")) {
      return rowSelection as RowSelectionV2;
    } else {
      const rowSelectionV1 = rowSelection as RowSelection<T>;
      if (rowSelectionV1?.showCheckbox) {
        return {
          isSelected: (rowIndex: number) => {
            const item = data[rowIndex];
            return item ? !!item[rowSelectionV1.selectBy.isSelectedKey as keyof T] : false;
          },
          isSelectionDisabled: (_rowIndex: number) => !rowSelectionV1.canSelect(),
          onSelectionChange: (rowIndexes: number[], selected: boolean) => {
            const rows = rowIndexes.map((index) => ({
              ...data[index],
              [rowSelectionV1.selectBy.isSelectedKey as keyof T]: selected,
            }));

            if (selected) {
              rowSelectionV1.onRowsSelected(rows);
            } else {
              rowSelectionV1.onRowsDeselected(rows);
            }
          },
        };
      } else {
        return undefined;
      }
    }
  }, [data, rowSelection]);

  const isSelected = rowSelectionV2?.isSelected || alwaysFalse;

  const isSelectionDisabled = rowSelectionV2?.isSelectionDisabled || alwaysFalse;

  const onSelectionChange = rowSelectionV2?.onSelectionChange || noop;

  const rowIndexes = useMemo(() => {
    if (!!infiniteLoader) {
      return [...Array(infiniteLoader).keys()];
    } else {
      return [...Array(data.length).keys()];
    }
  }, [data.length, infiniteLoader]);

  const selectedCount = useMemo<number>(
    () => rowIndexes.reduce((acc, rowIndex) => acc + (isSelected(rowIndex) ? 1 : 0), 0),
    [rowIndexes, isSelected],
  );

  const accordionBreakpointValue: boolean = useMemo(
    () =>
      accordionBreakpoint === "mobile" ? isMobile : accordionBreakpoint === "tablet" ? isTabletOrMobile : isDesktop,
    [accordionBreakpoint, isMobile, isTabletOrMobile, isDesktop],
  );

  const loadingHeight = useMemo(() => Math.max(BodyHeight, rowHeight * 5), [rowHeight, BodyHeight]);

  const { expandCallback, expandAllCallback, expandable, expandedKey = "" } = rowExpansion;

  const tableExpanded = useCallback(
    (index: number, newState: boolean, rowValue: T) => {
      const value = deepClone(expandedRows);
      value[index] = { expanded: newState, value: rowValue };
      setExpandedRows(value);
      setLastIndex(index);
    },
    [expandedRows],
  );

  const calculateBodyHeight = useCallback(
    (
      data: T[],
      expandedRows: DataTableExpansion<T>,
      rowHeight: number,
      headerHeight: number,
      maxHeightOffset: number,
      maxTableHeight: number,
      autogrow: boolean,
      horizontalScrollHeight: number,
      parentHeight: boolean,
      noScroll: boolean,
    ) => {
      if (noScroll) {
        setBodyHeight(0);
      } else {
        const tableHeight = data.length * (isMobile ? 200 : rowHeight) + headerHeight;
        if (autogrow) {
          const shouldAutoGrow = data.length <= 6 || isMobile;
          setTimeout(() => {
            let growth = 0;
            if (shouldAutoGrow) {
              const expandedValues = Object.values(expandedRows).filter((v) => !!v);
              if (expandedValues.length > 0) {
                for (const [key, value] of Object.entries(expandedRows)) {
                  if (!!value) {
                    const selector = `${rowPrefix}-${key}`;
                    const el: HTMLElement | null = getElement(selector);
                    growth += el?.scrollHeight || 0;
                  }
                }
              }
            }
            const calculated = tableHeight - headerHeight + growth;
            if (maxHeightOffset !== 0) {
              if (height! - maxHeightOffset > 0) {
                setBodyHeight(Math.min(calculated, height! - maxHeightOffset));
              } else {
                setBodyHeight(Math.min(calculated, height!));
              }
            } else if (maxTableHeight !== 0) {
              setBodyHeight(Math.min(calculated, maxTableHeight));
            } else {
              setBodyHeight(calculated);
            }
          }, 100);
        } else if (parentHeight) {
          const parentElement: HTMLElement | null | undefined = BCRTableRef?.current?.parentElement;
          if (!!parentElement) {
            let calculated: number = parentElement.offsetHeight;
            const parentElementStyles: CSSStyleDeclaration = getComputedStyle(parentElement);
            calculated -= parseFloat(parentElementStyles.paddingTop) + parseFloat(parentElementStyles.paddingBottom);
            const statusTextElement: HTMLElement | null = getElement("status-text");
            const statusTextHeight = statusTextElement?.offsetHeight ?? 0;
            if (!!calculated) {
              setBodyHeight(calculated - headerHeight - statusTextHeight - horizontalScrollHeight);
            }
          }
        } else {
          const calculated = tableHeight - headerHeight;
          if (maxHeightOffset !== 0) {
            if (height! - maxHeightOffset > 0) {
              setBodyHeight(Math.min(calculated, height! - maxHeightOffset));
            } else {
              setBodyHeight(Math.min(calculated, height!));
            }
          } else if (maxTableHeight !== 0) {
            setBodyHeight(maxTableHeight);
          } else {
            setBodyHeight(calculated);
          }
        }
      }
    },
    [isMobile, BCRTableRef],
  );

  const expandAll = useCallback(
    (value: boolean) => {
      const newExpanded: DataTableExpansion<T> = {};
      for (let i = 0; i < data.length; i++) {
        newExpanded[i] = { expanded: value, value: data[i] };
      }
      expandAllCallback(newExpanded);
      setExpandedRows(newExpanded);
    },
    [data, expandAllCallback, setExpandedRows],
  );

  const selectAllRows = useCallback(
    (selected: boolean) => {
      onSelectionChange(
        rowIndexes.filter((rowIndex) => isSelected(rowIndex) !== selected),
        selected,
      );
    },
    [isSelected, rowIndexes],
  );

  const checkForSelectAll = useCallback(() => {
    if (isSelected) {
      setAllRowsSelected(rowIndexes.every((rowIndex) => isSelected(rowIndex)));
    }
  }, [rowIndexes, isSelected]);

  const StatusTextRender = useCallback(() => {
    const count = !!pagination ? pagination.totalItems : data.length;
    if (!!statusTextOverride) {
      return statusTextOverride(selectedCount, count);
    }

    return (
      <>{!!rowSelectionV2 && selectedCount > 0 ? `${selectedCount} of ${data.length} selected` : `${count} items`}</>
    );
  }, [statusTextOverride, pagination, data.length, selectedCount, rowSelectionV2]);

  const calculateColumnSizes = useCallback(
    (finalColumns: DataTableColumn<T>[]) => {
      if (finalColumns.length > 0) {
        const newColumnSizes: number[] = [];
        const fixedColumns = finalColumns.filter((c: DataTableColumn<T>) => !!c.width);
        const fixedWidths =
          fixedColumns.map((c: DataTableColumn<T>) => c.width ?? 0).reduce(sum, 0) + addtlColumnWidths;
        finalColumns.forEach((col: DataTableColumn<T>, index: number) => {
          newColumnSizes[index] =
            col.width ||
            calculateCellSize(tableId, finalColumns.length - fixedColumns.length, fixedWidths, col.minWidth);
        });
        if (newColumnSizes.length >= columnSizes.length) {
          setColumnSizes(newColumnSizes);
        }
      }
    },
    [tableId, addtlColumnWidths, columnSizes],
  );

  const scrollbarHeightCheck = useCallback(() => {
    const hasScrollBar = (BCRTableBodyRef?.current?.scrollWidth ?? 0) > (BCRTableBodyRef?.current?.offsetWidth ?? 0);
    const horizontalScrollHeight = hasScrollBar ? 16 : 0;
    setHorizontalScrollHeight(horizontalScrollHeight);
  }, [BCRTableBodyRef?.current?.scrollWidth, BCRTableBodyRef?.current?.offsetWidth]);

  const onPaginationChanged = useCallback(() => {
    setLastIndex(undefined);
    setScrollToIndex(undefined);
    if (!!localOnPaginationChanged) {
      localOnPaginationChanged();
    }
  }, [localOnPaginationChanged]);

  const recalculateSizes = useCallback(
    debounce(() => {
      calculateBodyHeight(
        data,
        expandedRows,
        rowHeight,
        headerHeight,
        maxHeightOffset,
        maxTableHeight,
        autogrow,
        horizontalScrollHeight,
        parentHeight,
        !!tableHeights.noScroll,
      );

      calculateColumnSizes(finalColumns);
      calculateShouldShowScrollDown(onScrollParams);
      scrollbarHeightCheck();
      if (!initialized) {
        setInitialized(true);
      }
    }, 100),
    [
      data,
      expandedRows,
      lastIndex,
      rowHeight,
      headerHeight,
      height,
      maxHeightOffset,
      maxTableHeight,
      autogrow,
      horizontalScrollHeight,
      finalColumns,
      calculateBodyHeight,
      parentHeight,
      tableHeights.noScroll,
      onScrollParams,
    ],
  );

  const scrollDownTable = useCallback(() => {
    const currentIndex =
      !!onScrollParams?.scrollTop && onScrollParams?.scrollTop > 0 ? onScrollParams?.scrollTop / rowHeight : 0;
    const displayItems = !!onScrollParams?.clientHeight ? onScrollParams.clientHeight / rowHeight : 10;
    const toIdx = Math.floor(Math.min(data.length - 1, currentIndex + displayItems + 10));
    setScrollToIndex(toIdx);
  }, [onScrollParams, rowHeight, data]);

  const onTableScroll = (params: OnScrollParams) => {
    setOnScrollParams(params);
    setScrollToIndex(undefined);
    if (!!onScroll) {
      onScroll(params);
    }
    calculateShouldShowScrollDown(params);
  };

  const calculateShouldShowScrollDown = useCallback(
    (params?: OnScrollParams) => {
      const finalParams = params ?? onScrollParams;

      if (!!finalParams) {
        const tableViewSize = BodyHeight;
        const tableHeight = data.length * rowHeight;
        const atBottomOfTable = finalParams.clientHeight + finalParams.scrollTop >= finalParams.scrollHeight;
        const tableFitsAll = tableViewSize >= tableHeight;

        //simplify the logic below
        if (tableFitsAll || atBottomOfTable) {
          setShouldShowScrollDown(false);
        } else {
          setShouldShowScrollDown(true);
        }
      }
    },
    [onScrollParams, rowHeight, BodyHeight, headerHeight, data],
  );

  useEffect(() => {
    checkForSelectAll();
    setCopiedData(deepClone(data));
    recalculateSizes();
    let containsSubRows = false;
    data.forEach((d) => {
      if (!!d.subRows) {
        containsSubRows = true;
      }
    });
    if (containsSubRows !== tableSubRows) {
      setTableSubRows(containsSubRows);
    }
    if (!initialized) {
      setInitialized(true);
    }
  }, [data, loading, recalculateSizes]);

  useEffect(() => {
    calculateColumnSizes(finalColumns);
  }, [
    expandedRows,
    lastIndex,
    rowHeight,
    headerHeight,
    height,
    maxHeightOffset,
    maxTableHeight,
    autogrow,
    horizontalScrollHeight,
    finalColumns,
    parentHeight,
  ]);

  useEffect(() => {
    const dataValuesExpanded = Object.values(data).map(
      (value) => value[expandedKey as keyof typeof value] || value.expanded,
    );
    const values = Object.values(expandedRows).map(
      (value: IsExpandedType<T>) => value[expandedKey as keyof typeof value] || value.expanded,
    );
    setAnyAreExpanded(values.includes(true) || dataValuesExpanded.includes(true));
  }, [expandedRows]);

  useEffect(() => {
    setExpandedRows({});
  }, [sortColumn, sortDirection]);

  useEffect(() => {
    const tableHasSubRows = !!data.filter((d) => !!d.subRows).length;
    let addtlColumnWidthsLocal = 16;
    if (!!rowSelectionV2) {
      addtlColumnWidthsLocal += 31;
    }
    if (!!expansion) {
      addtlColumnWidthsLocal += 30;
    }
    if (!!actionsV2?.actions?.length) {
      addtlColumnWidthsLocal += 31;
    }
    if (tableHasSubRows) {
      addtlColumnWidthsLocal += 31;
    }
    setAddtlColumnWidths(addtlColumnWidthsLocal);
  }, [!!rowSelectionV2, expansion, actionsV2, data]);

  useEffect(() => {
    setFinalColumns(columns.filter((c) => c.visible === undefined || c.visible));
  }, [columns]);

  useEffect(() => {
    calculateColumnSizes(finalColumns);
  }, [finalColumns, data]);

  useEffect(() => {
    setTableWidth(
      columnSizes.reduce((a: number = minColumnWidth, b: number = minColumnWidth) => a + b, addtlColumnWidths),
    );
  }, [columnSizes, addtlColumnWidths]);

  useEffect(() => {
    setScrollToIndex(scrollToIndexProp);

    if (!virtualized && BCRTableRef?.current) {
      const el = getElement(`${tableId}-row-${scrollToIndexProp}`);
      if (!!el) {
        BCRTableRef?.current.scroll({
          top: el.offsetTop,
          left: el.offsetLeft,
        });
      }
    }

    if (!!scrollToIndexProp) {
      setTimeout(() => {
        setScrollToIndex(undefined);
      }, 500);
    }
  }, [scrollToIndexProp]);

  useEffect(() => {
    setHasHorizontalScroll(horizontalScrollHeight > 0);
  }, [horizontalScrollHeight]);

  useLayoutEffect(() => {
    let resizeObserver: ResizeObserver;
    if (BCRTableRef?.current?.parentElement) {
      resizeObserver = new ResizeObserver(recalculateSizes);
      resizeObserver.observe(BCRTableRef.current.parentElement);
    } else {
      recalculateSizes();
    }

    return () => resizeObserver?.disconnect();
  }, [recalculateSizes, BCRTableRef]);

  useLayoutEffect(() => {
    setTimeout(() => {
      if (!!onScrollParams) {
        onTableScroll(onScrollParams);
      }
    }, 1000);
  }, [BCRTableBodyRef?.current?.clientHeight]);

  useEffect(() => {
    calculateShouldShowScrollDown(onScrollParams);
  }, [BodyHeight]);

  return (
    <DataTableContext.Provider
      value={{
        type: virtualized && !tableHeights.noScroll ? "virtualized" : "not-virtualized",
        tableId,
        rowPrefix,
        initialized,
        className,
        loading,
        data: copiedData,
        columns: finalColumns,
        columnSizes,
        rowStylesGetter,
        statusTextRender: StatusTextRender,
        rowDisabledKey,
        actions: actionsV2,
        accordion: {
          accordion,
          accordionBreakpoint: accordionBreakpointValue,
        },
        pagination: {
          pagination,
          paginationProps,
          onPaginationChanged,
          setOnPaginationChanged: (value) => {
            setLocalOnPaginationChanged(() => value);
          },
        },
        heights: {
          noScroll: !!tableHeights.noScroll,
          bodyHeight: BodyHeight,
          loadingHeight,
          headerHeight,
          rowHeight,
        },
        widths: {
          tableWidth,
        },
        rowClick,
        sort: {
          column: sortColumn,
          direction: sortDirection,
          //@ts-ignore
          sortFunction,
        },
        addRows: {
          shouldAddRows: addRow,
          addRowFunction,
        },
        expansion: {
          expandable,
          expandedRows,
          expandAll,
          expansionElement: expansion,
          expandedKey,
          expandCallback,
          displayExpansionButton,
          displayExpandAllButton,
          anyAreExpanded,
          tableExpanded,
        },
        selection: {
          allRowsSelected,
          isSelectionDisabled,
          isRowSelected: isSelected,
          onSelectionChange,
          showSelectAll: selectAll,
          selectAllRows,
          showCheckbox: !!rowSelectionV2,
        },
        subRows: {
          hasSubRows: tableSubRows,
          subRowStylesGetter,
        },
        scroll: {
          showScrollDown: shouldShowScrollDown && showScrollDown,
          scrollDownTable,
          scrollToIndex: scrollToIndex || lastIndex,
          onScroll,
          onTableScroll,
          hasHorizontalScroll,
        },
        noDataMessage,
        infiniteLoader,
        styles: StylesOverride,
        setBCRTableRef,
        setBCRTableBodyRef,
      }}
    >
      {children}
    </DataTableContext.Provider>
  );
};

const useDataTable = <T extends DataTableDataType>() => {
  const dataTableContext = useContext<DataTableContextState<T> | undefined>(DataTableContext);
  if (dataTableContext === undefined) {
    throw new Error(`useDataTable must be used within a DataTableProvider`);
  }
  return dataTableContext;
};

export { DataTableContext, DataTableProvider, useDataTable };
