import { primitivesToOptions } from "../contexts/DropdownContext";

export const RoleConstants = {
  SUPER_ADMIN: "Super Admin",
  BASIC: "Basic",
  SCHEDULER: "Scheduler",
  SETTINGS_EDITOR: "Settings Editor",
};

export const RoleSelectionOptions = primitivesToOptions(Object.values(RoleConstants));
