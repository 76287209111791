import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "best-common-react";
import styled from "styled-components";

const BodyText = styled.div`
  color: ${props => props.theme.colors["fog"]};
  font-size: 14px;
  margin-bottom: 1rem;
`;

type ModalProps = {
  show: boolean;
  hide: () => void;
  setDefault: () => void;
  orgAbbrv: string;
};

const SetDefaultModal: React.FC<ModalProps> = ({ show, hide, setDefault, orgAbbrv }) => {
  return (
    <Modal show={show}>
      <ModalHeader>Set Default Camera Angles</ModalHeader>
      <ModalBody>
        <BodyText>
          Are you sure you would like to set the default angles for the <b>{orgAbbrv}</b>? This will override any prior
          default angles set.
        </BodyText>
      </ModalBody>
      <ModalFooter justifyContent="flex-end">
        <div>
          <Button
            className="me-2"
            onClick={() => {
              setDefault();
              hide();
            }}
          >
            Set Default
          </Button>
          <Button onClick={() => hide()}>Cancel</Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default SetDefaultModal;
