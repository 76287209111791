import React, { createContext, useContext, useReducer } from "react";
import { GameAngle } from "../modules/dashboards/common/AngleSelect";
import { GameDataDTO, ScheduleDTO } from "../types/DailyAssignmentTypes";
import {
  GameAdditionalSsmo,
  GameAnglesNotShown,
  GameCameraInput,
  GameHfrSsmo,
  GameTech,
  CamGameDataDTO,
  GameSettingDTO,
} from "../types/CamTypes";

interface CameraState extends CamGameDataDTO {
  selectedGame: GameDataDTO;
  selectedGameForm: GameSettingDTO;
}

type CameraContext = {
  cameraState: CameraState;
  dispatchCamera: React.Dispatch<any>;
};

const initialState: CameraState = {
  angleModifications: [],
  angles: [],
  feeds: [],
  gameSchedule: [],
  camGameSettings: [],
  offsets: [],
  techs: [],

  // Frontend metadata
  selectedGame: null,
  selectedGameForm: {
    gamePk: null,
    gameSetting: null,
    gameTechs: [],
    gameAnglesNotShowns: [],
    gameAdditionalSsmos: [],
    gameHfrSsmos: [],
    gameCameraInputs: [],
    replayOfficialAssignmentByGame: null,
    hasSfNotes: false,
  },
};

const CameraContext = createContext<CameraContext>({
  cameraState: initialState,
  dispatchCamera: null,
});

const CameraAction = {
  LOAD_SERIES: "LOAD_SERIES",
  SET_SELECTED_GAME: "SET_SELECTED_GAME",
  UPDATE_FROM_COPY: "UPDATE_FROM_COPY",
  UPDATE_ANGLE: "UPDATE_ANGLE",
  UPDATE_NOTES: "UPDATE_NOTES",
  UPDATE_TECHS: "UPDATE_TECHS",
  UPDATE_SETTING: "UPDATE_SETTING",
  CLEAR_ANGLES: "CLEAR_ANGLES",
};

const getForm = (game: ScheduleDTO, series: CamGameDataDTO): GameSettingDTO => {
  const gameSettingDTO: GameSettingDTO = series?.camGameSettings?.find(
    (x: GameSettingDTO): boolean => x.gamePk === game.gamePk,
  );
  return {
    gamePk: game.gamePk,
    gameSetting:
      series?.camGameSettings?.length && gameSettingDTO
        ? gameSettingDTO.gameSetting
        : { gamePk: game.gamePk, sfStatus: "OPEN", sfLock: false },
    gameTechs: series?.camGameSettings
      ?.flatMap((x: GameSettingDTO): GameTech[] => x.gameTechs)
      .filter((gt: GameTech): boolean => gt.gamePk === game.gamePk),
    gameAdditionalSsmos: series?.camGameSettings
      ?.flatMap((x: GameSettingDTO): GameAdditionalSsmo[] => x.gameAdditionalSsmos)
      .filter((ssmo: GameAdditionalSsmo): boolean => ssmo.gamePk === game.gamePk),
    gameAnglesNotShowns: series?.camGameSettings
      ?.flatMap((x: GameSettingDTO): GameAnglesNotShown[] => x.gameAnglesNotShowns)
      .filter((angle: GameAnglesNotShown): boolean => angle.gamePk === game.gamePk),
    gameHfrSsmos: series?.camGameSettings
      ?.flatMap((x: GameSettingDTO): GameHfrSsmo[] => x.gameHfrSsmos)
      .filter((gci: GameHfrSsmo): boolean => gci.gamePk === game.gamePk),
    gameCameraInputs: series?.camGameSettings
      ?.flatMap((x: GameSettingDTO): GameCameraInput[] => x.gameCameraInputs)
      .filter((gci: GameCameraInput): boolean => gci.gamePk === game.gamePk),
    replayOfficialAssignmentByGame: null,
    hasSfNotes: gameSettingDTO?.hasSfNotes,
  };
};

const reducer = (state: CameraState, action: any) => {
  switch (action.type) {
    case CameraAction.LOAD_SERIES:
      return {
        ...state,
        ...action.payload.series,
        selectedGame: action.payload.selectedGame,
        selectedGameForm: getForm(action.payload.selectedGame, action.payload.series),
      };
    case CameraAction.SET_SELECTED_GAME:
      // Set the selected game and populate our form for editing
      return {
        ...state,
        gameSchedule: state.gameSchedule.map(
          (game: ScheduleDTO): ScheduleDTO => (game.gamePk === action.payload.gamePk ? action.payload : game),
        ),
        selectedGame: action.payload,
        selectedGameForm: getForm(action.payload, state),
      };
    case CameraAction.UPDATE_FROM_COPY:
      const camGameSettingsCopy = [...state.camGameSettings];
      action.payload.forEach((newSetting: GameSettingDTO) => {
        const index = camGameSettingsCopy.findIndex(c => c.gamePk === newSetting?.gameSetting?.gamePk);
        camGameSettingsCopy[index] = newSetting;
      });
      return {
        ...state,
        camGameSettings: [...camGameSettingsCopy],
      };
    case CameraAction.CLEAR_ANGLES:
      return {
        ...state,
        selectedGameForm: {
          ...state.selectedGameForm,
          gameCameraInputs: [],
          gameHfrSsmos: [],
          gameAdditionalSsmos: [],
          gameAnglesNotShowns: [],
        },
      };
    case CameraAction.UPDATE_SETTING:
      const newCamGameSettings = [...state.camGameSettings];
      const targetSettingIndex = newCamGameSettings.findIndex(
        (gs: GameSettingDTO) => gs.gamePk === action.payload.gamePk,
      );

      if (targetSettingIndex >= 0) {
        newCamGameSettings[targetSettingIndex].gameSetting = action.payload.gameSetting;
      } else {
        newCamGameSettings.push(action.payload);
      }
      return {
        ...state,
        camGameSettings: [...newCamGameSettings],
      };
    case CameraAction.UPDATE_ANGLE: {
      const { data, field } = action.payload;
      return {
        ...state,
        selectedGameForm: {
          ...state.selectedGameForm,
          [field]: [
            ...state.selectedGameForm?.[field].filter(
              (x: GameAngle): boolean => x.gamePk === data.gamePk && x.sequence !== data.sequence,
            ),
            data,
          ],
        },
      };
    }
    case CameraAction.UPDATE_NOTES:
      return {
        ...state,
        selectedGameForm: {
          ...state.selectedGameForm,
          gameSetting: {
            ...state.selectedGameForm.gameSetting,
            ...action.payload,
          },
        },
      };
    case CameraAction.UPDATE_TECHS: {
      const { type } = action.payload;
      const gameTechsCopy = [...state.selectedGameForm.gameTechs];
      const techToReplaceIndex = gameTechsCopy.findIndex(x => x.type === type);
      if (techToReplaceIndex >= 0) {
        gameTechsCopy[techToReplaceIndex] = action.payload;
      } else {
        gameTechsCopy.push(action.payload);
      }
      return {
        ...state,
        selectedGameForm: {
          ...state.selectedGameForm,
          gameTechs: [...gameTechsCopy],
        },
      };
    }
    default:
      return state;
  }
};

const CameraContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <CameraContext.Provider
      value={{
        cameraState: state,
        dispatchCamera: dispatch,
      }}
    >
      {children}
    </CameraContext.Provider>
  );
};

const useCamera = (): CameraContext => {
  const context = useContext<CameraContext>(CameraContext);

  if (context == undefined) {
    throw new Error(`useCamera must be used within a CameraContextProvider`);
  }
  return context;
};

export { CameraContextProvider, useCamera, CameraAction };
