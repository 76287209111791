import { AsYouType, parsePhoneNumber } from "libphonenumber-js";
import { CountryCode, PhoneNumber } from "libphonenumber-js/types";

export const convertPhoneValueAsYouType = (value: string | number, countryCode: CountryCode): string => {
  value = value.toString();
  return new AsYouType(countryCode).input(value);
};

export const constructPhoneNumber = (value?: string | null, countryCode: CountryCode = "US"): PhoneNumber | null => {
  try {
    return value ? parsePhoneNumber(value!, countryCode) : null;
  } catch (error) {
    return null;
  }
};

export const isValidPhoneNumber = (value?: string, countryCode: CountryCode = "US"): boolean => {
  const phoneNumber: PhoneNumber | null = constructPhoneNumber(value, countryCode);
  return !!phoneNumber && phoneNumber.isValid();
};
