import React from "react";

export const PATCH_HEADERS = { headers: { "content-type": "application/json-patch+json" } };

export type PATCH_TYPE = "add" | "replace" | "remove";

export type Patch = {
  op: PATCH_TYPE;
  path: string;
  value: any;
};

export const getPatch = (fieldHasValue: boolean, path: string, value: any): Patch[] => {
  return [
    {
      op: fieldHasValue ? "replace" : "add",
      path: path,
      value: value,
    },
  ];
};
