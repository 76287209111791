import React from "react";
import { AlertHolder } from "best-common-react";
import styled from "styled-components";
import { useAlertContext } from "../../contexts/AlertsContext";

const AlertWrapper = styled.div`
  > div {
    > div {
      display: table;
      left: 0px;
      right: 0px;
      margin: 0 auto;
      width: fit-content;
      white-space: nowrap;

      .best-alert {
        .bcr-icon {
          margin-left: 1rem;
        }
      }
    }
  }
`;

const Alerts: React.FC = () => {
  const { alerts, removeAlert } = useAlertContext();

  return (
    <AlertWrapper id="bedroc-alerts">
      <AlertHolder
        alerts={alerts}
        onRemove={(id: number): void => {
          if (id) {
            removeAlert(id);
          }
        }}
      />
    </AlertWrapper>
  );
};

export default Alerts;
