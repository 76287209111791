import React from "react";
import { useTheme } from "../../../../hooks";
import { DatatableThemeType } from "../../../../types";
import Icon from "../../../icon/Icon";
import { ExpansionButtonHolder } from "../DatatableStyles";

export type ExpandedButtonProps = {
  expanded?: boolean;
  disabled?: boolean;
  onClick: () => void;
  loading: boolean;
  styles?: DatatableThemeType;
};

const ExpandedButton: React.FC<React.PropsWithChildren<ExpandedButtonProps>> = ({
  expanded = false,
  disabled = false,
  onClick,
  loading,
  styles = {},
}) => {
  const { Theme } = useTheme();
  const StylesOverride: DatatableThemeType = { ...Theme.datatable, ...styles };
  return (
    <ExpansionButtonHolder onClick={!disabled ? onClick : () => {}}>
      {!loading && (
        <>
          {disabled ? (
            <Icon
              iconName="fa-minus-circle"
              styles={{
                color: StylesOverride.expanded.icon.disabledColor,
                hoverColor: StylesOverride.expanded.icon.disabledColor,
              }}
            />
          ) : expanded ? (
            <Icon
              iconName="fa-minus-circle"
              styles={{
                color: StylesOverride.expanded.icon.color,
                hoverColor: StylesOverride.expanded.icon.hoverColor,
              }}
            />
          ) : (
            <Icon
              iconName="fa-plus-circle"
              styles={{
                color: StylesOverride.expanded.icon.color,
                hoverColor: StylesOverride.expanded.icon.hoverColor,
              }}
            />
          )}
        </>
      )}
    </ExpansionButtonHolder>
  );
};

export default ExpandedButton;
