import { useEffect, useState } from "react";
import { EventSourcePolyfill } from "event-source-polyfill";
import { useUser } from "../contexts/UserContext";
export const useSse = (sseUrl: string, eventType = "message", onOpen?: () => void, onError?: (error: any) => void) => {
  const [data, setData] = useState(null);
  const [sse, setSse] = useState(null);
  const { userInfo } = useUser();

  const closeSse = () => {
    if (sse) {
      sse.close();
    }
  };

  useEffect(() => {
    if (!userInfo.accessToken.accessToken) {
      return;
    }

    eventType = eventType || "message";
    const sse = new EventSourcePolyfill(sseUrl + "?eventType=" + eventType, {
      headers: {
        Authorization: `Bearer ${userInfo.accessToken.accessToken}`,
        "x-id-token": `${userInfo.idToken.idToken}`,
      },
    });

    sse.addEventListener(
      eventType,
      (e: any) => {
        setData(JSON.parse(e.data));
      },
      false,
    );

    sse.onopen = function() {
      if (onOpen && typeof onOpen === "function") {
        onOpen();
      }
    };
    sse.onerror = function(error: any) {
      if (onError && typeof onError === "function") {
        onError(error);
      }
    };
    setSse(sse);

    return () => {
      sse.close();
    };
  }, [userInfo]);

  return [data, closeSse] as const;
};
