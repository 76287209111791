/* eslint-disable react/no-array-index-key */
import { AlertConstants, Button, Checkbox, Select, ValueOpt } from "best-common-react";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { copySFGameSetting } from "../../../../api/CamApi";
import { useAlert } from "../../../../contexts/AlertsContext";
import { CameraAction, useCamera } from "../../../../contexts/CameraContext";
import { GameSettingDTO } from "../../../../types/CamTypes";
import { getCopyOption } from "../../../common/ValueOptHelper";
import { DashboardWidgetWrapper, Title } from "../../DashboardStyledComponents";

const CopyCheckBox = styled(Checkbox)`
  margin-bottom: 1rem;
  > label {
    font-size: 0.875rem;
    font-weight: normal;
    color: ${(props): string => props.theme.colors.fog};
  }
`;
const Section = styled.div`
  margin-bottom: 1rem;
`;

type CopyFromState = {
  copyFromOptions: ValueOpt<number>[]; // all options in select
  copyToOptions: ValueOpt<number>[]; // all checkbox options, filtering out game from select
  copyFromGame: ValueOpt<number>; // selected game from select
};

const CopyFrom: React.FC = () => {
  const { cameraState, dispatchCamera } = useCamera();
  const showAlert = useAlert();
  const initialState: CopyFromState = {
    copyFromGame: null,
    copyToOptions: [],
    copyFromOptions: [],
  };
  const [state, setState] = useState<CopyFromState>(initialState);

  const paste = () => {
    // endpoint handles one copy/paste at a time
    Promise.all(
      state.copyToOptions
        .filter(x => x.selected)
        .map(o => {
          return copySFGameSetting(state.copyFromGame.value, o.value);
        }),
    )
      .then(res => {
        showAlert("Successfully copied", AlertConstants.TYPES.SUCCESS);
        setState({
          ...state,
          copyToOptions: getCopyToOptions(),
        });
        dispatchCamera({
          type: CameraAction.UPDATE_FROM_COPY,
          payload: res,
        });
      })
      .catch(error => {
        showAlert("Error with copy", AlertConstants.TYPES.DANGER);
        console.log(error);
      });
  };

  // toggle button
  const isDisabled = (): boolean => {
    return !state.copyFromGame || state.copyToOptions.filter(o => o.selected).length === 0;
  };

  // use the ['selected'] property on the option to denoote checked, then update state
  const setCheckbox = (option: ValueOpt<number>, value: boolean) => {
    const copyToOptionsDupe = [...state.copyToOptions];
    copyToOptionsDupe.find(o => o.value === option.value).selected = value;
    setState({
      ...state,
      copyToOptions: copyToOptionsDupe,
    });
  };

  const getCopyToOptions = (): ValueOpt<number>[] => {
    return cameraState?.gameSchedule?.map(getCopyOption).filter((opt: ValueOpt<number>): boolean => {
      if (opt.value === state.copyFromGame?.value) return false;
      const gamePk = opt.value;
      const gameSettingDTO: GameSettingDTO = cameraState?.camGameSettings.find(
        (dto: GameSettingDTO): boolean => dto.gamePk === gamePk,
      );
      return !gameSettingDTO?.gameSetting?.sfLock;
    });
  };

  // create options and default selected game when schedule loads
  useEffect(() => {
    const options = cameraState?.gameSchedule?.map(getCopyOption);
    setState({
      ...state,
      copyFromOptions: options,
      copyToOptions: getCopyToOptions(),
      copyFromGame: { ...options.find(o => o.value === cameraState.selectedGame.gamePk) },
    });
  }, [cameraState.selectedGame]);

  // filter copyToOptions when copyFromOption is selected
  useEffect(() => {
    setState({
      ...state,
      copyToOptions: getCopyToOptions(),
    });
  }, [state.copyFromGame]);

  return (
    <>
      <DashboardWidgetWrapper>
        <Section>
          <Title>Copy From</Title>
          <Select
            id="copy-from"
            value={state.copyFromGame}
            options={state.copyFromOptions}
            onChange={(value: ValueOpt<number>) => {
              setState({ ...state, copyFromGame: value });
            }}
          />
        </Section>
        <Section>
          <Title>Paste To</Title>
          {state.copyToOptions.map(
            (o: ValueOpt<number>, index: number): React.ReactElement => {
              return (
                <CopyCheckBox
                  key={`${o.value}${index}`}
                  id={`copy-to-${o.value}${index}`}
                  checked={Boolean(o.selected)}
                  label={o.label}
                  onChange={(value: boolean) => setCheckbox(o, value)}
                />
              );
            },
          )}
        </Section>
        <Button disabled={isDisabled()} onClick={paste}>
          Paste
        </Button>
      </DashboardWidgetWrapper>
    </>
  );
};

export default CopyFrom;
