// TODO: Should we be using Midnight instead of Traditional?
import { MidnightColorType, ThemeType, TraditionalColors, Traditional } from "best-common-react";

// Eventually we may want to pull this theme into BCR as a re-useable theme.

export const MidnightColors: MidnightColorType = {
  // Default BCR Colors
  ...TraditionalColors,
  // Application Specific Colors
  cobalt: "#1b4980",
  midnight: "#010f21",
  gold: "#b29512",
  navy: "#03152e",
  slate: "#77879a",
  fog: "#dee5ed",
  green: "#00a651",
  orange: "#ce6c38",
  "night-blue": "#061b36",
  "background-blue": "#132e4e",
  "mlb-red": "#bf0d3e",
  "midnight-disabled": "#223e5f",
  "deep-blue": "#07254d",
};

const Midnight: ThemeType = {
  ...Traditional,
  name: "Midnight",
  colors: MidnightColors,
  // Add component style overrides below.
  // See BCR themes source for examples
  alert: {
    ...Traditional.alert,
    success: {
      background: MidnightColors.green,
      border: MidnightColors["midnight"],
      color: MidnightColors["midnight"],
    },
    warning: {
      background: TraditionalColors.yellow,
      border: MidnightColors["midnight"],
      color: MidnightColors["midnight"],
    },
    danger: {
      background: MidnightColors["mlb-red"],
      border: MidnightColors["midnight"],
      color: MidnightColors["midnight"],
    },
  },
  label: {
    ...Traditional.label,
    color: MidnightColors.slate,
    "font-weight": "normal",
    "asterisk-color": MidnightColors.slate,
    "asterisk-margin": "0.3rem",
    display: "flex",
  },
  checkbox: {
    ...Traditional.checkbox,
    color: MidnightColors["mlb-red"],
    labelColor: MidnightColors.slate,
    background: MidnightColors.black,
    borderColor: MidnightColors["cobalt"],
    // TODO: need clarification on how to style. disabled icon only appears on hover on perimeter
    disabledColor: MidnightColors.slate,
  },
  icon: {
    ...Traditional.icon,
    color: MidnightColors.slate,
    hoverColor: MidnightColors["mlb-red"],
    size: "1.1rem",
    disabledColor: MidnightColors["midnight-disabled"],
  },
  button: {
    ...Traditional.button,
    "border-radius": "0.875rem",
    default: {
      "background-color": MidnightColors.slate,
      "border-color": MidnightColors["background-blue"],
      color: MidnightColors["midnight"],
      hover: MidnightColors.slate,
    },
    primary: {
      "background-color": MidnightColors["mlb-red"],
      "border-color": MidnightColors["mlb-red"],
      color: MidnightColors["midnight"],
      hover: MidnightColors["mlb-red"],
    },
    secondary: {
      "background-color": MidnightColors["night-blue"],
      "border-color": MidnightColors["night-blue"],
      color: MidnightColors.slate,
      hover: MidnightColors["night-blue"],
    },
    disabled: {
      "background-color": MidnightColors["background-blue"],
      "border-color": MidnightColors["background-blue"],
      color: MidnightColors["midnight-disabled"],
      hover: MidnightColors["background-blue"],
    },
  },
  navigation: {
    ...Traditional.navigation,
    vertical: {
      ...Traditional.navigation.vertical,
      borderColor: MidnightColors["background-blue"],
      color: MidnightColors["slate"],
      background: `linear-gradient(165deg, ${MidnightColors["background-blue"]} 3%, ${MidnightColors["night-blue"]} 6%)`,
      signout: {
        ...Traditional.navigation.vertical.signout,
        color: MidnightColors["slate"],
      },
    },
  },
  select: {
    ...Traditional.select,
    placeholder: {
      fontWeight: 300,
      color: MidnightColors["slate"],
      fontStyle: "italic",
    },
    hover: {
      color: MidnightColors["night-blue"],
      background: MidnightColors.slate,
    },
    background: MidnightColors["background-blue"],
    borderColor: MidnightColors["black"],
    color: MidnightColors["fog"],
    multiBackground: MidnightColors["slate"],
    multiBorderRadius: "15px",
    actionColor: MidnightColors["slate"],
    required: {
      color: MidnightColors["slate"],
      background: `${MidnightColors["background-blue"]} !important`,
      borderColor: MidnightColors["black"],
    },
    disabled: {
      background: MidnightColors["midnight"],
    },
    clearIndicator: {
      padding: "0px 0px 0px 0px",
    },
  },
  textarea: {
    ...Traditional.textarea,
    placeholder: {
      ...Traditional.textarea.placeholder,
      fontWeight: 300,
      color: MidnightColors["slate"],
      fontStyle: "italic",
    },
    input: {
      ...Traditional.textarea.input,
      color: MidnightColors["fog"],
      background: MidnightColors["background-blue"],
      borderColor: MidnightColors["black"],
      disabled: {
        ...Traditional.textarea.input.disabled,
        color: MidnightColors["slate"],
        background: MidnightColors["midnight"],
        borderColor: MidnightColors["black"],
      },
      missed: {
        ...Traditional.textarea.input.missed,
        color: MidnightColors["slate"],
        background: `${MidnightColors["background-blue"]} !important`,
        borderColor: MidnightColors["black"],
      },
      required: {
        ...Traditional.textarea.input.required,
        color: MidnightColors["slate"],
        background: `${MidnightColors["background-blue"]} !important`,
        borderColor: MidnightColors["black"],
      },
    },
  },
  hint: {
    ...Traditional.hint,
    color: MidnightColors["slate"],
  },
  input: {
    ...Traditional.input,
    color: MidnightColors["fog"],
    "border-color": MidnightColors["black"],
    size: "0.875rem",
    background: `${MidnightColors["background-blue"]} !important`,
    missed: {
      color: TraditionalColors["slate"],
      background: `${MidnightColors["background-blue"]} !important`,
      "border-color": TraditionalColors["black"],
    },
    required: {
      color: TraditionalColors["slate"],
      background: `${MidnightColors["background-blue"]} !important`,
      "border-color": TraditionalColors["black"],
    },
    disabled: {
      color: TraditionalColors["slate"],
      background: TraditionalColors["midnight"],
      "border-color": TraditionalColors["black"],
    },
  },
  datatable: {
    ...Traditional.datatable,
    datatable: {
      border: "",
    },
    scrollBar: {
      background: MidnightColors["midnight"],
      element: {
        color: MidnightColors["background-blue"],
        borderColor: MidnightColors["background-blue"],
      },
    },
    header: {
      ...Traditional.datatable.header,
      background: MidnightColors["night-blue"],
      color: MidnightColors["slate"],
      borderBottomColor: MidnightColors["night-blue"],
    },
    row: {
      background: MidnightColors["midnight"],
      selectedBackground: MidnightColors["night-blue"],
      color: MidnightColors["fog"],
      separatorColor: MidnightColors["night-blue"],
      disabledBackground: TraditionalColors["grey"],
      expandedBackground: TraditionalColors["almost-white"],
    },
    column: {
      background: TraditionalColors.white,
      sortedBackground: "none",
      color: MidnightColors["fog"],
    },
    sortIcon: {
      ...Traditional.datatable.sortIcon,
      color: MidnightColors.slate,
      hoverColor: MidnightColors["mlb-red"],
    },
    moreActions: {
      ...Traditional.datatable.moreActions,
      addRowText: {
        color: MidnightColors["mlb-red"],
      },
    },
  },
  modal: {
    ...Traditional.modal,
    backdrop: MidnightColors["midnight"],
    backdropOpacity: 1,
    border: `${MidnightColors["background-blue"]} 1px solid`,
    header: {
      background: MidnightColors["night-blue"],
      fontColor: MidnightColors["slate"],
      bottomBorderColor: "unset",
      closeColor: MidnightColors["midnight"],
      padding: "1rem 1rem",
      fontWeight: "600",
      fontStyle: "italic",
      fontSize: "1.3125rem",
    },
    body: {
      ...Traditional.modal.body,
      background: MidnightColors["night-blue"],
      fontColor: MidnightColors["fog"],
      padding: "1rem",
    },
    footer: {
      background: MidnightColors["night-blue"],
      fontColor: MidnightColors["slate"],
      borderTopColor: "unset",
      padding: "1rem 1rem",
    },
  },
  datepicker: {
    ...Traditional.datepicker,
    input: {
      ...Traditional.datepicker.input,
      actionColor: MidnightColors["slate"],
      actionHoverColor: MidnightColors["mlb-red"],
    },
    calendar: {
      borderColor: MidnightColors["black"],
      header: {
        background: MidnightColors["night-blue"],
        color: MidnightColors["fog"],
        borderColor: MidnightColors["black"],
        select: {
          background: MidnightColors["background-blue"],
          color: MidnightColors["fog"],
        },
        dayOfWeek: {
          color: MidnightColors["slate"],
        },
      },
      body: {
        background: MidnightColors["background-blue"],
        color: MidnightColors["fog"],
        borderColor: MidnightColors["black"],
        day: {
          color: MidnightColors["fog"],
          colorOutsideMonth: MidnightColors["fog"],
          active: {
            color: MidnightColors["midnight"],
            background: MidnightColors["mlb-red"],
            borderColor: MidnightColors["midnight"],
          },
          hover: {
            background: MidnightColors["midnight"],
          },
          disabled: {
            color: MidnightColors["midnight"],
            background: MidnightColors["mlb-red"],
            borderColor: MidnightColors["midnight"],
          },
        },
      },
    },
  },
  link: {
    ...Traditional.link,
    color: MidnightColors["mlb-red"],
    hover: MidnightColors["mlb-red"],
  },
  tooltip: {
    background: TraditionalColors["fog"],
    color: TraditionalColors["background-blue"],
  },
  placeholder: {
    color: MidnightColors["slate"],
  },
  typography: {
    span: {
      size: ".9rem",
      color: MidnightColors.fog,
      weight: 400,
      style: "normal",
      width: "100%",
    },
    div: {
      size: ".9rem",
      color: MidnightColors.fog,
      weight: 400,
      style: "normal",
      width: "100%",
    },
    body1: {
      size: ".9rem",
      color: MidnightColors.fog,
      weight: 400,
      style: "normal",
      width: "100%",
    },
    body2: {
      size: "1rem",
      color: MidnightColors.fog,
      weight: 400,
      style: "normal",
      width: "100%",
    },
    body3: {
      size: "1.1rem",
      color: MidnightColors.fog,
      weight: 400,
      style: "normal",
      width: "100%",
    },
    caption: {
      size: ".9rem",
      color: MidnightColors.fog,
      weight: 400,
      style: "italic",
      width: "100%",
    },
    h1: {
      size: "6rem",
      color: MidnightColors.fog,
      weight: 600,
      style: "normal",
      width: "100%",
    },
    h2: {
      size: "3.75rem",
      color: MidnightColors.fog,
      weight: 600,
      style: "normal",
      width: "100%",
    },
    h3: {
      size: "3rem",
      color: MidnightColors.fog,
      weight: 600,
      style: "normal",
      width: "100%",
    },
    h4: {
      size: "2.125rem",
      color: MidnightColors.fog,
      weight: 600,
      style: "normal",
      width: "100%",
    },
    h5: {
      size: "1.5rem",
      color: MidnightColors.fog,
      weight: 600,
      style: "normal",
      width: "100%",
    },
    h6: {
      size: "1.25rem",
      color: MidnightColors.fog,
      weight: 600,
      style: "normal",
      width: "100%",
    },
    subtitle1: {
      size: ".8rem",
      color: MidnightColors.fog,
      weight: 400,
      style: "normal",
      width: "100%",
    },
    subtitle2: {
      size: ".8rem",
      color: MidnightColors.fog,
      weight: 400,
      style: "italic",
      width: "100%",
    },
  },
};

export default Midnight;
