import clsx from "clsx";
import React, { CSSProperties, useState } from "react";
import {
  DropdownIndicatorProps,
  GroupBase,
  PropsValue,
  components as ReactSelectComponents,
  StylesConfig,
  ValueContainerProps,
  createFilter,
} from "react-select";
import { Icon } from "../../../components";
import { useTheme } from "../../../hooks";
import { BaseInputProps, InputSize, SelectThemeType } from "../../../types";
import { RequiredInput } from "../../required";
import { ReadOnlyFormatter } from "../input";
import Label from "../label/Label";
import { SelectContainer, StyledSelect } from "./SelectStyles";

export interface ValueOpt<SelectValueType> extends PropsValue<any> {
  label: React.ReactNode;
  value: SelectValueType;
  disabled?: boolean;
}

export interface ValueOptGrouped<SelectValueType> extends GroupBase<ValueOpt<SelectValueType>> {
  label: string;
  options: ValueOpt<SelectValueType>[];
}

type SelectOptionsType<SelectValueType> = ValueOpt<SelectValueType>[] | ValueOptGrouped<SelectValueType>[];

export type SingleSelectStyles = StylesConfig<ValueOpt<any>, false>;
export type MultiSelectStyles = StylesConfig<ValueOpt<any>, true>;
export type BCRSelectStyles = SingleSelectStyles & {
  disabled?: {
    background?: string;
  };
};

export interface FilterOptionOption<Option> {
  readonly label: string;
  readonly value: string;
  readonly data: Option;
}

export interface SelectFilterConfig<T> {
  readonly ignoreCase?: boolean;
  readonly ignoreAccents?: boolean;
  readonly stringify?: (option: FilterOptionOption<T>) => string;
  readonly trim?: boolean;
  readonly matchFrom?: "any" | "start";
}

export type SelectProps<SelectValueType, IsMulti, Group> = BaseInputProps & {
  placeholder?: string;
  components?: object;
  required?: boolean;
  styles?: SingleSelectStyles | MultiSelectStyles | BCRSelectStyles;
  isMulti?: boolean;
  autofocus?: boolean;
  searchable?: boolean;
  onChange: (value?: ValueOpt<SelectValueType> | ValueOpt<SelectValueType>[]) => void;
  options: SelectOptionsType<SelectValueType>;
  value?: null | ValueOpt<SelectValueType> | ValueOpt<SelectValueType>[];
  formatGroupLabel?: (group: GroupBase<any>) => React.ReactNode;
  portal?: null | HTMLElement;
  /** end adorner for the input field */
  endAdorner?: React.ReactNode;
  /** start adorner for the input field */
  startAdorner?: React.ReactNode;
  filterConfig?: SelectFilterConfig<SelectValueType>;
  /** removes text wrap on select dropdown options label */
  singleLine?: boolean;
  blurInputOnSelect?: boolean;
  menuPlacement?: "auto" | "bottom" | "top";
};

export const NaOpt: ValueOpt<string> = { value: "N/A", label: "" };

const getSize = (size?: InputSize): string => {
  switch (size) {
    case "sm":
      return "20px";
    case "lg":
      return "40px";
    case "md":
    default:
      return "28px";
  }
};

const IndicatorSeparator: React.FC = () => <span style={{ display: "none" }} />;

const ValueContainer = <SelectValueType,>({ children, ...rest }: ValueContainerProps) => {
  //@ts-ignore
  const { startAdorner, endAdorner, isMulti, singleLine } = rest.selectProps as SelectProps<SelectValueType>;

  return (
    <ReactSelectComponents.ValueContainer {...rest}>
      {!isMulti && (!!startAdorner || !!endAdorner) ? (
        <div className={clsx("d-flex align-items-center", { " flex-wrap": !singleLine })}>
          {!!startAdorner && <div className="me-2 mt-1">{startAdorner}</div>}
          {children}
          {!!endAdorner && <div className="me-2 mt-1">{endAdorner}</div>}
        </div>
      ) : (
        <>
          {!!startAdorner && <div className="me-2 mt-1">{startAdorner}</div>}
          {children}
          {!!endAdorner && <div className="me-2 mt-1">{endAdorner}</div>}
        </>
      )}
    </ReactSelectComponents.ValueContainer>
  );
};

const DropdownIndicator = <SelectValueType, IsMulti, Group>(dropdownIndicatorProps: DropdownIndicatorProps) => {
  //@ts-ignore
  const { disabled, bcrStyles } = dropdownIndicatorProps.selectProps as SelectProps<SelectValueType>;
  return (
    <ReactSelectComponents.DropdownIndicator {...dropdownIndicatorProps}>
      <Icon iconName="fa-caret-down" className="me-3" styles={{ color: bcrStyles.actionColor }} disabled={disabled} />
    </ReactSelectComponents.DropdownIndicator>
  );
};

const Select = <SelectValueType, IsMulti, Group>(props: SelectProps<SelectValueType, IsMulti, Group>) => {
  const {
    name,
    className = "",
    id,
    label,
    placeholder,
    required = false,
    disabled = false,
    readOnly = false,
    clearable = false,
    searchable = true,
    isMulti = false,
    autofocus = false,
    options,
    value,
    onChange,
    portal = document.body,
    gutterBottom = false,
    styles = {},
    showNA = false,
    startAdorner,
    endAdorner,
    singleLine = false,
    formatGroupLabel,
    components = {},
    inputSize = "md",
    // NOTE: filterConfig defaults pulled from here: https://react-select.com/advanced#custom-filter-logic
    filterConfig = {
      ignoreCase: true,
      ignoreAccents: true,
      matchFrom: "any",
      stringify: (option) => `${option.label} ${option.value}`,
      trim: true,
    },
    validateFunc,
    blurInputOnSelect,
    menuPlacement,
  } = props;

  const {
    container = {},
    multiValue = {},
    multiValueLabel = {},
    multiValueRemove = {},
    menu = {},
    menuPortal = {},
    control,
    option = {},
    valueContainer = {},
    indicatorsContainer = {},
    dropdownIndicator = {},
    clearIndicator = {},
    singleValue = {},
  } = styles;

  const { Theme } = useTheme();
  //@ts-ignore
  const StylesOverride: SelectThemeType = { ...Theme.select, ...styles };

  const SelectStyles = {
    container: (selectStyles: CSSProperties, _state: any) => ({
      ...selectStyles,
      ...container,
    }),
    input: (selectStyles: CSSProperties, _state: any) => ({
      ...selectStyles,
      color: StylesOverride.color,
      height: getSize(inputSize),
    }),
    placeholder: (selectStyles: CSSProperties, _state: any) => ({
      ...selectStyles,
      fontStyle: StylesOverride.placeholder.fontStyle,
      color: StylesOverride.placeholder.color,
      fontWeight: StylesOverride.placeholder.fontWeight,
      fontSize: "0.875rem",
      opacity: 1,
    }),
    multiValue: (selectStyles: CSSProperties, _state: any) => ({
      ...selectStyles,
      color: StylesOverride.color,
      background: StylesOverride.multiBackground,
      borderRadius: StylesOverride.multiBorderRadius,
      ...multiValue,
    }),
    multiValueLabel: (selectStyles: CSSProperties, _state: any) => ({
      ...selectStyles,
      ...multiValueLabel,
    }),
    multiValueRemove: (selectStyles: CSSProperties, _state: any) => ({
      ...selectStyles,
      color: StylesOverride.actionColor,
      borderRadius: "14px",
      height: "20px",
      width: "20px",
      marginTop: "2px",
      marginRight: "2px",
      "&:hover": {
        color: StylesOverride.hover.color,
        backgroundColor: StylesOverride.hover.background,
      },
      background: StylesOverride.background,
      ...multiValueRemove,
    }),
    menu: (selectStyles: CSSProperties, _state: any) => ({
      ...selectStyles,
      zIndex: 2000,
      background: StylesOverride.background,
      color: StylesOverride.color,
      ...menu,
    }),
    menuPortal: (selectStyles: CSSProperties, _state: any) => ({
      ...selectStyles,
      zIndex: 2050,
      ...menuPortal,
    }),
    option: (selectStyles: CSSProperties, state: any) => ({
      ...selectStyles,
      background: state.isFocused ? StylesOverride.hover.background : StylesOverride.background,
      color: state.isFocused ? StylesOverride.hover.color : StylesOverride.color,
      ...option,
    }),
    valueContainer: (selectStyles: CSSProperties, state: any): ValueContainerProps => {
      const styles = {
        ...selectStyles,
        background: StylesOverride.background,
        fontSize: Theme.input.size,
        borderTopLeftRadius: StylesOverride.borderRadius,
        borderBottomLeftRadius: StylesOverride.borderRadius,
        ...valueContainer,
      };
      if (state.isDisabled) {
        styles.backgroundColor = `${StylesOverride.disabled.background} !important`;
      }
      return {
        ...styles,
      };
    },
    indicatorsContainer: (selectStyles: CSSProperties, state: any) => ({
      ...selectStyles,
      padding: 0,
      borderTopRightRadius: StylesOverride.borderRadius,
      borderBottomRightRadius: StylesOverride.borderRadius,
      background: StylesOverride.background,
      ...indicatorsContainer,
    }),
    dropdownIndicator: (selectStyles: CSSProperties, state: any) => ({
      ...selectStyles,
      padding: 0,
      ...dropdownIndicator,
    }),
    clearIndicator: (selectStyles: CSSProperties, state: any) => ({
      ...selectStyles,
      padding: StylesOverride.clearIndicator.padding,
      ...clearIndicator,
    }),
    control: (selectStyles: CSSProperties, state: any) => {
      const shadowTheme = {
        valid: "rgba(0,123,255,.25)",
        invalid: "rgba(220,53,69,.25)",
      };
      const styles = {
        ...selectStyles,
        minWidth: 60,
        borderRadius: StylesOverride.borderRadius,
        borderColor: StylesOverride.borderColor,
        backgroundColor: StylesOverride.background,
        ...control,
      };
      if (state.isFocused) {
        //@ts-ignore
        styles.boxShadow = `0 0 0 0.2rem ` + shadowTheme["valid"];
      }
      if (state.isDisabled) {
        styles.backgroundColor = `${StylesOverride.disabled.background} !important`;
      }
      return {
        ...styles,
      };
    },
    singleValue: (selectStyles: CSSProperties, state: any) => ({
      ...selectStyles,
      color: StylesOverride.color,
      ...singleValue,
    }),
  };

  const [focus, setFocus] = useState(false);
  const toggleFocus = () => {
    setFocus(!focus);
  };

  //@ts-ignore
  const onChangeSelect = (option) => {
    //@ts-ignore
    onChange(option);
  };

  const getMultiReadOnlyString = (values: ValueOpt<SelectValueType>[] | ValueOpt<SelectValueType> | null): string => {
    if (Array.isArray(values) && values.length) {
      return values.map((v) => v.label).join(", ");
    }
    return "";
  };

  const toggleNA = (value: boolean) => {
    if (value) {
      //@ts-ignore
      onChange(NaOpt);
    } else {
      onChange(undefined);
    }
  };

  //@ts-ignore
  const isNA: boolean = showNA && NaOpt === value;

  return (
    <SelectContainer className={clsx({ "mb-2": gutterBottom })}>
      {!!label && (
        <Label
          htmlFor={id}
          required={required && !readOnly}
          showNA={showNA && !readOnly}
          isNA={isNA}
          onChange={toggleNA}
        >
          {label}
        </Label>
      )}
      {!readOnly ? (
        <RequiredInput required={required && !disabled && !readOnly} validateFunc={validateFunc}>
          <StyledSelect
            className={clsx(className, { "is-na": isNA })}
            //@ts-ignore
            styles={SelectStyles}
            name={name}
            id={id}
            onFocus={() => {
              toggleFocus();
            }}
            onBlur={() => {
              toggleFocus();
            }}
            startAdorner={startAdorner}
            endAdorner={endAdorner}
            singleLine={singleLine}
            placeholder={placeholder}
            isDisabled={disabled || isNA}
            menuPortalTarget={portal}
            isClearable={(isMulti ? !!value?.length : !!value) && clearable}
            isMulti={isMulti}
            onChange={onChangeSelect}
            value={value === undefined || isNA ? null : value}
            closeMenuOnSelect={!isMulti}
            bcrStyles={StylesOverride}
            autoFocus={autofocus}
            formatGroupLabel={formatGroupLabel}
            isSearchable={searchable}
            menuPlacement={menuPlacement}
            //@ts-ignore
            components={{ IndicatorSeparator, DropdownIndicator, ValueContainer, ...components }}
            options={options}
            filterOption={createFilter(filterConfig as any)}
            blurInputOnSelect={blurInputOnSelect}
          />
        </RequiredInput>
      ) : (
        <ReadOnlyFormatter
          value={
            isMulti
              ? getMultiReadOnlyString(value as ValueOpt<SelectValueType>[])
              : !!value
              ? (value as ValueOpt<SelectValueType>).label
              : ""
          }
          id={id}
        />
      )}
    </SelectContainer>
  );
};

export default Select;
