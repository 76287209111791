import styled from "styled-components";
import { TextareaThemeType } from "../../../types";

type TextareaStylesProps = {
  missed: boolean;
  resizeable: boolean;
  styles: TextareaThemeType;
  height?: number;
};

export const TextareaStyled = styled.textarea.attrs(() => ({
  name: "best-textarea-input",
}))<TextareaStylesProps>`
  && {
    ::placeholder {
      color: ${(props) => props.styles.placeholder!["color"]} !important;
      font-weight: ${(props) => props.styles.placeholder!["fontWeight"]};
      opacity: 1;
      font-style: ${(props) => props.styles.placeholder!["fontStyle"]};
      font-size: 0.875rem;
    }
    :focus {
      background: ${(props) => props.styles.input!.focused?.background || props.styles.input!.background};
      border: 1px solid ${(props) => props.styles.input!["borderColor"]};
      outline: none;
      box-shadow: none;
    }
    :disabled {
      color: ${(props) => props.styles.input!.disabled!["color"]};
      background-color: ${(props) => props.styles.input!.disabled!["background"]};
    }
    color: ${(props) => props.styles.input!["color"]};
    font-weight: ${(props) => props.styles.input!["fontWeight"]};
    background: ${(props) => props.styles.input!.background};
    font-size: 0.9rem;
    border: 1px solid ${(props) => props.styles.input!["borderColor"]};
    &.form-control.is-invalid {
      border: 1px solid
        ${(props) =>
          props.missed ? props.styles.input!.missed!["borderColor"] : props.styles.input!.required!["borderColor"]};
      color: ${(props) =>
        props.missed ? props.styles.input!.missed!["color"] : props.styles.input!.required!["color"]};
      background-image: none;
    }
    resize: ${(props) => (props.resizeable ? "" : "none")};
    height: ${(props) => {
      if (!!props.height) {
        return `${props.height}px; ::-webkit-scrollbar { display: none; } scrollbar-width: none;`;
      } else {
        return "";
      }
    }}
`;
