import React, { lazy, Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { useUser } from "./contexts/UserContext";
import "./App.css";
import Alerts from "./modules/common/Alerts";
import "./styles/Tables.css";

const AuthenticatedApp = lazy(() => import("./modules/common/AuthenticatedApp"));
const UnauthenticatedApp = lazy(() => import("./modules/common/UnauthenticatedApp"));

const App: React.FC = () => {
  const { loggedIn } = useUser();

  return (
    <Suspense fallback={<h1>Loading...</h1>}>
      <Alerts />
      <BrowserRouter>{loggedIn ? <AuthenticatedApp /> : <UnauthenticatedApp />}</BrowserRouter>
    </Suspense>
  );
};

export default App;
