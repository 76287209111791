import React from "react";
import { Icon } from "../../../icon";
import { useDataTable } from "../DataTableContext";
import { ScrollDownContainer } from "../DatatableStyles";

const TableScrollDown = () => {
  const {
    scroll: { scrollDownTable, showScrollDown, hasHorizontalScroll },
  } = useDataTable();
  return showScrollDown ? (
    <ScrollDownContainer onClick={scrollDownTable} hasHorizontalScroll={hasHorizontalScroll}>
      <Icon iconName="fa-chevron-down" />
    </ScrollDownContainer>
  ) : null;
};

export default TableScrollDown;
