import React from "react";
import { FlexRow } from "../../../common/CommonStyledComponents";
import AnglesNotShown from "./AnglesNotShown";
import BroadcastInformation from "./BroadcastInformation";
import HFRsTaken from "./HFRsTaken";
import CameraInputs from "./CameraInputs";
import Notes from "./Notes";
import SSMOsNotTaken from "./SSMOsNotTaken";
import TechInformation from "./TechInformation";

const SeriesDetails: React.FC = () => {
  return (
    <div>
      <FlexRow>
        <BroadcastInformation />
        <TechInformation />
        <Notes />
      </FlexRow>
      <CameraInputs />
      <FlexRow>
        <div>
          <HFRsTaken />
          <SSMOsNotTaken />
        </div>
        <AnglesNotShown />
      </FlexRow>
    </div>
  );
};

export default SeriesDetails;
