import React from "react";
import { useTheme } from "../../../../hooks";
import { ASC, DatatableThemeType, DESC, SortDirection } from "../../../../types";
import Icon from "../../../icon/Icon";

export type SortIconProps = {
  direction?: SortDirection;
  styles: DatatableThemeType;
};

const SortIcon: React.FC<React.PropsWithChildren<SortIconProps>> = ({ direction, styles = {} }) => {
  const { Theme } = useTheme();
  const StylesOverride: DatatableThemeType = { ...Theme.datatable, ...styles };
  if (direction === DESC) {
    return <Icon className="ms-2" iconName="fa-caret-down" styles={{ ...StylesOverride.sortIcon }} />;
  } else if (direction === ASC) {
    return <Icon className="ms-2" iconName="fa-caret-up" styles={StylesOverride.sortIcon} />;
  } else {
    return null;
  }
};

export default SortIcon;
