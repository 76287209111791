import { PropsWithChildren } from "react";
import { DataTableDataType, FormatterType } from "../../../../types";
import { areObjectsEqual, getElement } from "../../../../utils";

const scrollbarVisible = (elem: string | HTMLElement | null) => {
  const el: HTMLElement | null = typeof elem === "string" ? getElement(elem) : elem;
  return !!el && el!.scrollHeight > el!.clientHeight;
};

export const calculateColumnWidth = (
  element: HTMLElement | null,
  numColumns: number,
  fixedColumnWidths = 0,
  subtractScrollbar = false,
): number[] => {
  if (!element) return [0, 0];
  const n: number = Math.max(numColumns, 1);
  const parent: HTMLElement | null = !!element ? element.parentElement : null;
  const totalWidth: number = element.offsetWidth - (subtractScrollbar && scrollbarVisible(parent) ? 15 : 0);
  const freeSpace: number = Math.max(totalWidth - fixedColumnWidths, 0);
  const baseWidth: number = Math.floor(freeSpace / n);
  const remaining: number = Math.floor(freeSpace % n);

  return [baseWidth, remaining];
};

export const calculateCellSize = (
  selector: string,
  numColumns: number,
  usedSpace: number,
  minColumnWidth = 0,
  subtractScrollbar = false,
): number => {
  const el: HTMLElement | null = getElement(selector);
  const [val]: number[] = calculateColumnWidth(el, numColumns, usedSpace, subtractScrollbar);
  const width: number = Math.max(val, minColumnWidth);
  return width;
};

export const distribute = (rem: number, numColumns: number): number[] => {
  const n: number = Math.max(numColumns, 1);
  const result: number[] = [...Array(n)].fill(0);
  const inc: number = Math.ceil(rem / n);
  let dec: number = rem;
  let i = 0;
  while (dec > 0 && i < n) {
    result[i] += inc;
    dec -= inc;
    i++;
  }

  return result.reverse();
};

export function formatterPropsCompare<T extends DataTableDataType>(
  prevProps: Readonly<PropsWithChildren<FormatterType<T>>>,
  nextProps: Readonly<PropsWithChildren<FormatterType<T>>>,
): boolean {
  return (
    !nextProps.editable &&
    areObjectsEqual(prevProps.value, nextProps.value) &&
    areObjectsEqual(prevProps.styles, nextProps.styles) &&
    areObjectsEqual(prevProps.row, nextProps.row) &&
    prevProps.expanded === nextProps.expanded &&
    prevProps.rowIndex === nextProps.rowIndex &&
    prevProps.className === nextProps.className &&
    prevProps.subrow === nextProps.subrow &&
    prevProps.icon === nextProps.icon
  );
}
