import React from "react";
import { useTheme } from "../../../../hooks";
import { DatatableThemeType } from "../../../../types";
import Icon from "../../../icon/Icon";
import { SubRowButtonHolder } from "../DatatableStyles";

export type SubrowButtonProps = {
  expanded?: boolean;
  onClick: (value: boolean) => void;
  loading: boolean;
  disabled?: boolean;
  styles?: DatatableThemeType;
};

const SubrowButton: React.FC<React.PropsWithChildren<SubrowButtonProps>> = ({
  expanded = false,
  onClick,
  loading,
  disabled = false,
  styles = {},
}) => {
  const { Theme } = useTheme();
  const StylesOverride: DatatableThemeType = { ...Theme.datatable, ...styles };
  return (
    <SubRowButtonHolder
      onClick={() => {
        if (!disabled && !loading) {
          onClick(!expanded);
        }
      }}
    >
      {!loading && (
        <Icon
          iconName={expanded ? "fa-minus-circle" : "fa-plus-circle"}
          styles={{
            color: disabled ? StylesOverride.subrow.icon.disabledColor : StylesOverride.subrow.icon.color,
            hoverColor: disabled ? StylesOverride.subrow.icon.disabledColor : StylesOverride.subrow.icon.hoverColor,
          }}
        />
      )}
    </SubRowButtonHolder>
  );
};

export default SubrowButton;
