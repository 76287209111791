import React, { FC, ReactNode } from "react";

export type ComponentToRender = ReactNode | FC<any>;

export const renderComponent = (component?: ComponentToRender, props: any = {}): React.ReactNode => {
  if (component) {
    if (typeof component === typeof {}) {
      //@ts-ignore
      return component;
    } else {
      const RenderComponent = component;
      //@ts-ignore
      return <RenderComponent {...props} />;
    }
  }
  return <span />;
};
