import React from "react";
import { useTheme } from "../../../hooks";
import { LabelThemeType } from "../../../types";
import { deepMerge, NestedPartial } from "../../../utils";
import { LabelStyles } from "../label/LabelStyles";
import { RequiredIndicatorStyled } from "./RequiredStyles";

type RequiredIndicatorProps = {
  /** Overrideable styles */
  styles?: NestedPartial<LabelStyles>;
};

const RequiredIndicator: React.FC<React.PropsWithChildren<RequiredIndicatorProps>> = ({ styles = {} }) => {
  const { Theme } = useTheme();
  const StylesOverride: LabelThemeType = deepMerge<LabelThemeType>(Theme.label, styles);
  return <RequiredIndicatorStyled styles={StylesOverride}>*</RequiredIndicatorStyled>;
};

export default RequiredIndicator;
