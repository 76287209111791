import { Link as RRLink } from "react-router-dom";
import styled from "styled-components";
import { LinkThemeType } from "../../types";

type LinkStylesProps = {
  styles: LinkThemeType;
};

export const LinkStyled = styled(RRLink).attrs(() => ({
  name: "best-link",
}))<LinkStylesProps>`
  &&& {
    color: ${(props) => props.styles.color};
    font-size: ${(props) => props.styles.size};
    &:hover {
      cursor: pointer;
      color: ${(props) => props.styles.hover};
    }
    text-decoration: ${(props) => props.styles.textDecoration};
    text-wrap: nowrap;
  }
`;
