import styled from "styled-components";

export type LabelStyles = {
  "font-weight"?: string | number;
  color?: string;
  "asterisk-color"?: string;
  "asterisk-margin"?: string;
  display?: string;
  fontSize?: number | string;
  marginBottom?: number;
};

type LabelStylesProps = {
  styles: LabelStyles;
};

export const LabelStyled = styled.label<LabelStylesProps>`
  font-weight: ${(props) => props.styles["font-weight"]};
  color: ${(props) => props.styles.color};
  margin-bottom: ${(props) => props.styles.marginBottom || 0}px;
  font-size: ${(props) => (props.styles.fontSize ? props.styles.fontSize : props.theme.label.size)};
  display: ${(props) => props.theme.label.display};
`;
