import { useState } from "react";

export const useDebounce = (fn: (...args: any[]) => any, ms = 300) => {
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | undefined>();
  return function (this: any, ...args: any[]) {
    if (timeoutId !== undefined) {
      clearTimeout(timeoutId);
    }
    setTimeoutId(setTimeout(() => fn.apply(this, args), ms));
  };
};

export function debounce(f: (...args: any[]) => any, delay: number): () => void {
  let timer: NodeJS.Timeout;

  return function (...args) {
    clearTimeout(timer);
    //@ts-ignore
    timer = setTimeout(() => f.apply(this, args), delay);
  };
}
