import clsx from "clsx";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Hint } from "../../../components";
import { useTheme } from "../../../hooks";
import { BaseInputProps, LabelThemeType, TextareaThemeType } from "../../../types";
import { NestedPartial, deepMerge, isNA } from "../../../utils";
import RequiredInput from "../../required/RequiredInput";
import { ReadOnlyFormatter } from "../input";
import Label from "../label/Label";
import { TextareaStyled } from "./TextareaStyles";

export type TextAreaStyles = {
  textarea: TextareaThemeType;
  label: LabelThemeType;
};

export type TextAreaProps = BaseInputProps & {
  value?: string;
  onChange: (value?: string) => void;
  rows?: number;
  maxLength?: number;
  resizable?: boolean;
  autogrow?: boolean;
  styles?: NestedPartial<TextAreaStyles>;
};

const TextArea: React.FC<React.PropsWithChildren<TextAreaProps>> = ({
  id,
  value = "",
  onChange,
  disabled = false,
  required = false,
  missed = false,
  label,
  className = "",
  placeholder = "",
  maxLength,
  onBlur,
  readOnly = false,
  resizable = false,
  autogrow = false,
  rows = 4,
  gutterBottom = false,
  showNA = false,
  disablePaste = false,
  readOnlyFormatter,
  onClick,
  validateFunc,
  styles = {},
}) => {
  const [height, setHeight] = useState<number | undefined>(undefined);
  const selfRef = useRef<HTMLTextAreaElement | null>(null);
  const { Theme } = useTheme();
  const StylesOverride: TextAreaStyles = deepMerge<TextAreaStyles>(
    { textarea: Theme.textarea, label: Theme.label },
    styles,
  );
  const ReadOnly = readOnlyFormatter || ReadOnlyFormatter;

  const toggleNA = (value: boolean) => {
    if (value) {
      onChange("N/A");
    } else {
      onChange(undefined);
    }
  };

  const isNAValue: boolean = isNA(showNA, value);

  useLayoutEffect(() => {
    if (!!selfRef?.current && autogrow) {
      const scrollHeight = selfRef.current.scrollHeight;
      const minRowHeight = rows * 25;
      setHeight(Math.max(scrollHeight, minRowHeight));
    } else {
      setHeight(undefined);
    }
  }, [value, autogrow]);

  useEffect(() => {
    if (selfRef?.current) {
      if (disablePaste) {
        selfRef.current.onpaste = (e: ClipboardEvent) => {
          e.preventDefault();
        };
      } else {
        selfRef.current.onpaste = (e: ClipboardEvent) => {
          //leave default functionality
        };
      }
    }
  }, [selfRef, disablePaste]);

  return (
    <>
      <Label
        htmlFor={id}
        required={required && !readOnly}
        missed={missed}
        showNA={showNA && !readOnly}
        isNA={isNAValue}
        onChange={toggleNA}
        styles={StylesOverride.label}
      >
        {label}
      </Label>
      {readOnly ? (
        <ReadOnly id={id} value={value} onClick={onClick} />
      ) : (
        <div className={clsx({ "mb-2": gutterBottom })}>
          <RequiredInput required={required && !readOnly} isNA={isNAValue} validateFunc={validateFunc}>
            <TextareaStyled
              ref={selfRef}
              id={id}
              value={isNAValue ? "" : value}
              className={clsx("form-control bcr-textarea-input", className)}
              onChange={(event) => onChange(event.target.value)}
              disabled={disabled || isNAValue}
              required={required}
              missed={missed}
              placeholder={placeholder}
              readOnly={readOnly}
              rows={rows}
              onBlur={onBlur}
              maxLength={maxLength}
              resizeable={resizable && !autogrow}
              height={height}
              styles={StylesOverride.textarea}
            />
          </RequiredInput>
          {!!maxLength && (
            <Hint>
              {value?.length || 0} / {maxLength}
            </Hint>
          )}
        </div>
      )}
    </>
  );
};

export default TextArea;
