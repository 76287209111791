import React from "react";
import styled from "styled-components";
import Pulse, { PulsingProps } from "./Pulse";

const Filler = styled.div`
  background: ${(props) => props.theme.colors.grey};
  width: 100%;
  height: 100%;
  border-radius: 8px;
`;

const PulseFiller: React.FC<React.PropsWithChildren<PulsingProps>> = (props) => {
  return (
    <Pulse {...props}>
      <Filler />
    </Pulse>
  );
};

export default PulseFiller;
