import React from "react";
import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import axiosRetry from "axios-retry";
import { LocalStorageConstants } from "../constants/LocalStorageConstants";
import { useUser } from "../contexts/UserContext";

interface HttpInterceptorProps {
  children: React.ReactNode;
}

const HttpInterceptor: React.FC<HttpInterceptorProps> = ({ children }) => {
  const { logout } = useUser();

  axiosRetry(axios, {
    retries: 3, //attempts to retry
    retryCondition: error => {
      console.log("Error fetching from API will retry...");
      return !!error?.response && (error.response.status === 401 || error.response.status === 403);
    }, // only retry on unauthenticated errors to prevent retrying 500s unless that is wanted
    retryDelay: retryCount => retryCount * 1000, // wait 1 second between retrys
  });

  axios.interceptors.request.use((config: AxiosRequestConfig) => {
    const oktaTokenStr =
      localStorage.getItem(LocalStorageConstants.OKTA_TOKEN) || '{"accessToken": "", "idToken": "" }';

    const { accessToken = {}, idToken = {} } = JSON.parse(oktaTokenStr);

    if (config.url.indexOf("/oauth/token") < 0 || config.headers.addToken) {
      config.headers.Authorization = `Bearer ${accessToken.accessToken}`;
      config.headers["x-id-token"] = idToken.idToken;
      // NOTE: I had originally used "userInfo" from UserContext to grab the user's selected sportId,
      // but it was pulling the context's default value rather than the actual value. I was unable to
      // figure out why this was happening, as that would be the preffered way of retreiving this value
      if (config.url.includes("bedroc")) {
        config.headers["x-sport-id"] = Number(localStorage.getItem("sportId"));
      }
    }
    return config;
  });

  axios.interceptors.response.use(
    (resp: AxiosResponse) => {
      if (!resp) return null;
      return resp;
    },
    (err: AxiosError) => {
      if (!!err.response && (err.response.status === 401 || err.response.status === 403)) {
        logout();
      }
      return Promise.reject(err);
    },
  );

  return <div>{children}</div>;
};

export default HttpInterceptor;
