import React from "react";
import { Security } from "@okta/okta-react";
import { UserProvider, oktaAuth } from "../contexts/UserContext";
import { AlertsProvider } from "../contexts/AlertsContext";
import HttpInterceptor from "../api/HttpInterceptor";
import Midnight from "../theme/Midnight";
import { BCR } from "best-common-react";
import { DropdownsProvider } from "../contexts/DropdownContext";
import { SseNotificationProvider } from "../contexts/SseNotificationContext";
import "best-common-react/lib/styles/bcr.css";

const WrapperProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const restoreOriginalUri = async (_oktaAuth: any, originalUri: string): Promise<void> => {
    window.location.replace(originalUri);
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <UserProvider>
        <HttpInterceptor>
          <SseNotificationProvider>
            <AlertsProvider>
              <DropdownsProvider>
                <BCR theme={Midnight}>{children}</BCR>
              </DropdownsProvider>
            </AlertsProvider>
          </SseNotificationProvider>
        </HttpInterceptor>
      </UserProvider>
    </Security>
  );
};

export default WrapperProvider;
