import clsx from "clsx";
import React, { forwardRef, useEffect, useImperativeHandle, useRef } from "react";
import { Typography } from "../../../layout";
import { DataTableDataType, DataTableRef } from "../../../types";
import MobileAccordions from "../../accordion/MobileAccordions";
import { DataTableProvider, useDataTable } from "./DataTableContext";
import { BodyStyled, TableStyled } from "./DatatableStyles";
import DataTableNonVirtualized from "./bodies/DataTableNonVirtualized";
import DataTableVirtualized from "./bodies/DataTableVirtualized";
import Header from "./common/Header";
import TablePagination from "./common/TablePagination";
import TableScrollDown from "./common/TableScrollDown";
import TableStatus from "./common/TableStatus";
import { DataTableProps } from "./types/DataTableComponentTypes";

const TableBody = () => {
  const { type } = useDataTable();
  if (type === "not-virtualized") {
    return <DataTableNonVirtualized />;
  } else {
    return <DataTableVirtualized />;
  }
};

const DataTable = <T extends DataTableDataType>({ tableRef }: { tableRef: React.ForwardedRef<DataTableRef> }) => {
  const {
    initialized,
    className,
    accordion: { accordion, accordionBreakpoint },
    tableId,
    noDataMessage,
    data,
    loading,
    heights: { loadingHeight, bodyHeight, noScroll },
    expansion: { expandAll },
    scroll: { onTableScroll },
    styles,
    setBCRTableRef,
    setBCRTableBodyRef,
  } = useDataTable<T>();
  const BCRTableRef = useRef<HTMLDivElement>(null);
  const BCRTableBodyRef = useRef<HTMLTableElement>(null);

  // exposes functions to the parent component (if the parent passes a ref).
  // to add more functions, just update the returned object in this hook.
  useImperativeHandle(tableRef, () => ({
    toggleRowExpansion: (value: boolean) => {
      expandAll(value);
    },
  }));

  useEffect(() => {
    setBCRTableBodyRef(BCRTableBodyRef);
  }, [BCRTableBodyRef]);

  useEffect(() => {
    setBCRTableRef(BCRTableRef);
  }, [BCRTableRef]);

  return (
    <div id={tableId} className={clsx("bcr-table", "w-100", "h-100", className)}>
      {initialized && (
        <>
          <TableStatus />

          {!!accordion && accordionBreakpoint ? (
            <MobileAccordions />
          ) : (
            <TableStyled styles={styles} ref={BCRTableRef}>
              <Header />
              <BodyStyled
                noScroll={noScroll}
                height={loading ? loadingHeight : bodyHeight}
                className="bcr-table-body"
                ref={BCRTableBodyRef}
                styles={styles}
              >
                {!!data?.length || loading ? (
                  <TableBody />
                ) : !!noDataMessage?.length ? (
                  <div className="d-flex justify-content-center align-items-center py-3">
                    <Typography variant="div" className="text-center">
                      {noDataMessage}
                    </Typography>
                  </div>
                ) : null}
              </BodyStyled>
            </TableStyled>
          )}
          <TableScrollDown />
          <TablePagination />
        </>
      )}
    </div>
  );
};

const DataTableWrapper = forwardRef(
  <T extends DataTableDataType>(props: DataTableProps<T>, ref: React.ForwardedRef<DataTableRef>) => {
    return (
      <DataTableProvider<T> tableProps={props}>
        <DataTable tableRef={ref} />
      </DataTableProvider>
    );
  },
);

export default DataTableWrapper;
