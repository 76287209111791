import React, { useCallback } from "react";
import { DataTableDataType } from "../../../../types";
import { Pagination } from "../../../pagination";
import { useDataTable } from "../DataTableContext";

const TablePagination = <T extends DataTableDataType>() => {
  const {
    pagination: { pagination, paginationProps, onPaginationChanged },
    styles,
  } = useDataTable<T>();

  const onValueChanged = useCallback(() => {
    onPaginationChanged();
  }, [onPaginationChanged]);

  return (
    <>
      {!!pagination && (
        <Pagination
          {...paginationProps}
          pagination={pagination}
          onValueChanged={onValueChanged}
          styles={styles}
          className="mt-2"
        />
      )}
    </>
  );
};

export default TablePagination;
