import React, { createContext, useContext, useEffect, useState } from "react";
import { useSse } from "../hooks/useSse";
import { SSE_REGISTER_URL } from "../api/EventApi";
import {
  DashboardStatusDTO,
  ReviewPatchSseDto,
  ReviewPlayInfo,
  ReviewRefreshDto,
  ReviewTimingCalcDTO,
  ReviewTimingNotes,
} from "../types/ReviewDetailsTypes";
import { SlackAlertType } from "../types/SlackAlertTypes";
import { GameHfrSsmo, GameSetting } from "../types/CamTypes";
import { ReplayOfficialAssignmentByGame } from "../types/DailyAssignmentTypes";
import { GameCheck } from "../types/CheckTypes";
import { IssueLog, IssueLogMiscNotes } from "../types/IssueLogTypes";
import { SpecialLink, SpecialLinkAngle } from "../types/SpecialLinkTypes";

type NotificationPayload = {
  lastUpdated?: string;
  updatedUserEmail?: string;
  updatedUserName?: string;
};

type NotificationOptions = {
  slackAlert?: NotificationPayload & {
    data?: SlackAlertType;
  };
  gameSetting?: NotificationPayload & {
    data?: GameSetting;
  };
  replayOfficialAssignmentByGame?: NotificationPayload & {
    data?: ReplayOfficialAssignmentByGame;
  };
  gameCheck?: NotificationPayload & {
    data?: GameCheck;
  };
  gameCheckDelete?: NotificationPayload & {
    data?: number;
  };
  gameHfrSsmo?: NotificationPayload & {
    data?: GameHfrSsmo;
  };
  gameHfrSsmoOrder?: NotificationPayload & {
    data?: GameHfrSsmo[];
  };
  issueLog?: NotificationPayload & {
    data?: IssueLog;
  };
  issueLogDelete?: NotificationPayload & {
    data?: number;
  };
  issueLogMiscNotes?: NotificationPayload & {
    data?: IssueLogMiscNotes;
  };
  refreshOperatorDashboard?: NotificationPayload & {
    data?: number;
  };
  refreshSupportDashboard?: NotificationPayload & {
    data?: number;
  };
  refreshRmDashboard?: NotificationPayload & {
    data?: number;
  };
  refreshFloaterDashboard?: NotificationPayload & {
    data?: number;
  };
  refreshTdDashboard?: NotificationPayload & {
    data?: number;
  };
  refreshAdminDashboard?: NotificationPayload & {
    data?: number;
  };
  rmReviewStatus?: NotificationPayload & {
    data?: DashboardStatusDTO;
  };
  reviewRefresh?: NotificationPayload & {
    data?: ReviewRefreshDto;
  };
  floaterReviewStatus?: NotificationPayload & {
    data?: DashboardStatusDTO;
  };
  slackAlertFloater?: NotificationPayload & {
    data?: SlackAlertType;
  };
  deleteReview?: NotificationPayload & {
    data?: number;
  };
  specialLink?: NotificationPayload & {
    data?: SpecialLink;
  };
  deleteSpecialLink?: NotificationPayload & {
    data?: number;
  };
  specialLinkAngle?: NotificationPayload & {
    data?: SpecialLinkAngle[];
  };
  reviewTimingUpdate?: NotificationPayload & {
    data?: ReviewPatchSseDto;
  };
  reviewTimingCalc?: NotificationPayload & {
    data?: ReviewTimingCalcDTO;
  };
  reviewRocStaffUpdate?: NotificationPayload & {
    data?: ReviewPatchSseDto;
  };
  reviewScoreBaseoutUpdate?: NotificationPayload & {
    data?: ReviewPatchSseDto;
  };
  reviewLinkUpdate?: NotificationPayload & {
    data?: ReviewPatchSseDto;
  };
  reviewTdDataUpdate?: NotificationPayload & {
    data?: ReviewPatchSseDto;
  };
  reviewInfoUpdate?: NotificationPayload & {
    data?: ReviewPatchSseDto;
  };
  reviewPlayInfoUpdate?: NotificationPayload & {
    data?: ReviewPatchSseDto;
  };
  reviewPlayInfoParentClear?: NotificationPayload & {
    data?: ReviewPlayInfo;
  };
  reviewTimingNotesSave?: NotificationPayload & {
    data?: ReviewTimingNotes;
  };
  reviewTimingNotesDelete?: NotificationPayload & {
    data?: ReviewTimingNotes;
  };
  multipleReviewUpdate?: NotificationPayload & {
    // data is just gamePK
    data?: number;
  };
};

type SseNotificationContext = {
  notification: NotificationOptions;
};

const SseNotificationContext = createContext<SseNotificationContext>({
  notification: null,
});

const SseNotificationProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [notification, setNotification] = useState<NotificationOptions>({});
  const [data] = useSse(SSE_REGISTER_URL, "sseNotification");

  // the first useEffect triggers all dependent components to perform necessary re-renders
  // the second useEffect empties out the notification object to prevent unnecessary re-renders
  // the second useEffect will not "overwrite" necessary updates from the first useEffect

  useEffect(() => {
    if (data?.payload) {
      const newNotification = { ...notification, ...data.payload?.entities };
      setNotification(newNotification);
    }
  }, [data]);

  useEffect(() => {
    if (Object.keys(notification).length > 0) {
      setNotification({});
    }
  }, [notification]);

  return <SseNotificationContext.Provider value={{ notification }}>{children}</SseNotificationContext.Provider>;
};

const useSseNotification = (): SseNotificationContext => {
  const context = useContext<SseNotificationContext>(SseNotificationContext);
  if (context === undefined) {
    throw new Error(`useSseNotificationContext must be used within a SseNotificationProvider`);
  }
  return context;
};

export { SseNotificationContext, SseNotificationProvider, useSseNotification };
