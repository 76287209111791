import React from "react";
import styled from "styled-components";
import { useTheme } from "../../../../hooks";
import { DataTableDataType } from "../../../../types";
import { Hint } from "../../../hint";
import { Pulse } from "../../../pulse";
import { useDataTable } from "../DataTableContext";

const Status = styled.div`
  position: sticky;
`;

const TableStatus = <T extends DataTableDataType>() => {
  const { Theme } = useTheme();
  const { loading, statusTextRender } = useDataTable<T>();

  return (
    <Status>
      <Hint styles={{ ...Theme.hint, fontStyle: "italic" }} id="status-text">
        {loading ? <Pulse>...</Pulse> : statusTextRender()}
      </Hint>
    </Status>
  );
};

export default TableStatus;
