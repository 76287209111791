import styled from "styled-components";
import { TextThemeType } from "../../types";

type TextElementStylesProps = {
  styles: TextThemeType;
};

export const TextStyled = styled.span<TextElementStylesProps>`
  color: ${(props) => props.styles.color};
  font-size: ${(props) => props.styles.fontSize};
  font-weight: ${(props) => props.styles.fontWeight};
  font-style: ${(props) => props.styles.fontStyle};
`;
