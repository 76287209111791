import { DataTable, DataTableColumn, FormatterType, Label } from "best-common-react";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { CollapseToggle } from "../DashboardStyledComponents";
import { MidnightColors } from "../../../theme/Midnight";

interface Props {
  data: any[];
  columns: DataTableColumn<any>[];
  rowsVisible?: number; // Default number of rows to show.. if set, the rest will be hidden behind a show more
  showToggle?: boolean;
  showLabel?: boolean;
  label?: string;
  labelColWidth?: number;
  expandedByDefault?: boolean;
  tableWidth?: number;
  tableHeights?: any;
  highlightedRowIds?: number[];
}

interface NoScrollProps {
  label: string;
  labelColWidth: number;
}

const NoScroll = styled.div<NoScrollProps>`
  div .ReactVirtualized__Grid {
    overflow: hidden !important;
  }

  .ReactVirtualized__Grid__innerScrollContainer > div {
    width: auto !important;
  }

  div .bcr-table-row {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  div .bcr-table-header {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .label-col {
    background-color: ${(props): string => props.theme.colors["night-blue"]};
    min-width: 0 !important;
    display: flex;
    align-items: center;
    width: ${(props): string => `${props.labelColWidth}px`} !important;
  }
`;

const ROW_HEIGHT = 40;
const getTableHeight = (rows: number): number => {
  return ROW_HEIGHT * rows;
};

const DashboardTable: React.FC<Props> = ({
  data,
  columns,
  rowsVisible = 0,
  showToggle = false,
  showLabel = true,
  label = "",
  labelColWidth = 60,
  expandedByDefault = false,
  tableWidth,
  tableHeights = {},
  highlightedRowIds = [],
}) => {
  let initialMaxHeight = 0;
  if (rowsVisible > 0) {
    initialMaxHeight = getTableHeight(rowsVisible);
  } else {
    initialMaxHeight = getTableHeight(data.length);
  }

  const [open, setOpen] = useState<boolean>(!showToggle);
  const [maxHeight, setMaxHeight] = useState<number>(initialMaxHeight);

  const toggleOpen = () => {
    const newOpen = !open;
    if (!newOpen) {
      setMaxHeight(getTableHeight(rowsVisible));
    } else {
      setMaxHeight(getTableHeight(data.length));
    }
    setOpen(newOpen);
  };

  useEffect(() => {
    if (expandedByDefault) {
      toggleOpen();
    }
  }, [expandedByDefault]);

  const labelCol: DataTableColumn<any> = {
    name: "",
    key: "label",
    cellClass: "label-col",
    readonlyFormatter: (row: FormatterType<any>) => (
      <Label
        htmlFor={row.row.label}
        styles={highlightedRowIds?.includes(row.row.id) ? { color: MidnightColors.yellow } : {}}
      >
        {!!label ? label : row.row.label} {row.row.seq ?? row.row.sequence}
      </Label>
    ),
  };

  const defaultTableHeights = {
    autogrow: !showToggle,
    rowHeight: ROW_HEIGHT,
    maxHeightOffset: 0,
    maxTableHeight: maxHeight,
  };
  const calculatedTableHeights = { ...defaultTableHeights, ...tableHeights };

  return (
    <div style={{ width: tableWidth + "px" }}>
      <NoScroll label={label} labelColWidth={labelColWidth}>
        <DataTable
          data={data}
          columns={showLabel ? [labelCol, ...columns] : columns}
          statusTextOverride={() => ""}
          tableHeights={calculatedTableHeights}
        />
      </NoScroll>
      {showToggle && (
        <CollapseToggle
          onClick={() => {
            toggleOpen();
          }}
        >
          {open ? "show less" : "show more"}
        </CollapseToggle>
      )}
    </div>
  );
};

export default DashboardTable;
