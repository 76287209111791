import App from "./App";
import React from "react";
import { createRoot } from "react-dom/client";
import WrapperProvider from "./modules/WrapperProvider";
import "./assets/fonts/static/WorkSans-Italic.ttf";
import "./assets/fonts/static/WorkSans-LightItalic.ttf";
import "./assets/fonts/static/WorkSans-Medium.ttf";
import "./assets/fonts/static/WorkSans-MediumItalic.ttf";
import "./assets/fonts/static/WorkSans-Regular.ttf";
import "./assets/fonts/static/WorkSans-SemiBold.ttf";
import "./assets/fonts/static/WorkSans-SemiBoldItalic.ttf";
import "./assets/fonts/WorkSans-VariableFont_wght.ttf";
import { datadogRum } from "@datadog/browser-rum";

if (window.MLBBest.envVariables.APP_ENV !== "local") {
  datadogRum.init({
    applicationId: window.MLBBest.envVariables.DATADOG_APP_ID,
    clientToken: window.MLBBest.envVariables.DATADOG_CLIENT_TOKEN,
    site: "us5.datadoghq.com",
    service: "best-bedroc",
    env: window.MLBBest.envVariables.APP_ENV,
    sampleRate: 100,
    premiumSampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: "mask-user-input",
  });

  datadogRum.startSessionReplayRecording();
}
createRoot(document.getElementById("frontend-starter-app")).render(
  <WrapperProvider>
    <App />
  </WrapperProvider>,
);
