import styled from "styled-components";
import { LabelStyles } from "../label/LabelStyles";

type LabelStylesProps = {
  styles: LabelStyles;
};

export const RequiredIndicatorStyled = styled.span.attrs(() => ({
  className: "bcr-required-asterisk",
}))<LabelStylesProps>`
  color: ${(props) => props.styles["asterisk-color"]};
  margin: ${(props) => props.styles["asterisk-margin"]};
  line-height: 1;
`;
