import { DataTableColumn, FormatterType, ValueOpt } from "best-common-react";
import React from "react";
import { saveUnusedAngles, updateGameAnglesNotShown } from "../../../../api/GameSettingApi";
import {
  primitivesToOptions,
  lookupsToOptions,
  lookupToOption,
  primitiveToOption,
} from "../../../../contexts/DropdownContext";
import { useCamera } from "../../../../contexts/CameraContext";
import { GameAnglesNotShown } from "../../../../types/CamTypes";
import DashboardTable from "../../common/DashboardTable";
import { DashboardWidgetWrapperNoGradient, Title } from "../../DashboardStyledComponents";
import { AngleSelect, getFeedOptions } from "../../common/AngleSelect";
import { DashboardConstants } from "../../../../constants/DashboardConstants";

const INPUTS = 10;
const EXPANDED_INPUTS = 5;

const AnglesNotShown: React.FC = () => {
  const { cameraState, dispatchCamera } = useCamera();
  const { selectedGameForm } = cameraState;
  const { gameAnglesNotShowns } = selectedGameForm;
  const locked = selectedGameForm?.gameSetting?.sfLock;

  const getForm = (seq: number): GameAnglesNotShown => {
    return gameAnglesNotShowns?.find((x: GameAnglesNotShown): boolean => seq === x.sequence);
  };

  const angleOptions: ValueOpt<any>[] = lookupsToOptions(cameraState.angles);
  const angleModOptions: ValueOpt<any>[] = primitivesToOptions(cameraState.angleModifications);
  const offsetOptions: ValueOpt<any>[] = primitivesToOptions(cameraState.offsets);
  const feedOptions: ValueOpt<any>[] = primitivesToOptions(cameraState.feeds);

  const gamePk: number = cameraState?.selectedGame?.gamePk;

  const rows = [...Array(INPUTS + EXPANDED_INPUTS).keys()].map((i: number) => {
    return {
      label: "",
      sequence: i + 1,
    };
  });

  const columns: DataTableColumn<any>[] = [
    {
      name: "Camera Angle",
      key: "field",
      width: 180,
      editFormatter: (props: FormatterType<any>) => (
        <AngleSelect
          {...props}
          dashboard={DashboardConstants.CAM.value}
          type="gameAnglesNotShowns"
          field="angle"
          width={180}
          gamePk={gamePk}
          form={getForm(props.row.sequence)}
          dispatch={dispatchCamera}
          options={angleOptions}
          getValue={lookupToOption}
          saveApi={saveUnusedAngles}
          updateApi={updateGameAnglesNotShown}
          locked={locked}
        />
      ),
    },
    {
      name: "Angle Modif",
      key: "angleModif",
      width: 150,
      editFormatter: (props: FormatterType<any>) => (
        <AngleSelect
          {...props}
          dashboard={DashboardConstants.CAM.value}
          type="gameAnglesNotShowns"
          field="angleModification"
          width={150}
          gamePk={gamePk}
          form={getForm(props.row.sequence)}
          dispatch={dispatchCamera}
          options={angleModOptions}
          getValue={primitiveToOption}
          saveApi={saveUnusedAngles}
          updateApi={updateGameAnglesNotShown}
          locked={locked}
        />
      ),
    },
    {
      name: "Offset",
      key: "angleOffset",
      width: 120,
      editFormatter: (props: FormatterType<any>) => (
        <AngleSelect
          {...props}
          dashboard={DashboardConstants.CAM.value}
          type="gameAnglesNotShowns"
          field="angleOffset"
          width={120}
          gamePk={gamePk}
          form={getForm(props.row.sequence)}
          dispatch={dispatchCamera}
          options={offsetOptions}
          getValue={primitiveToOption}
          saveApi={saveUnusedAngles}
          updateApi={updateGameAnglesNotShown}
          locked={locked}
        />
      ),
    },
    {
      name: "Feed",
      key: "feed",
      width: 160,
      editFormatter: (props: FormatterType<any>) => (
        <AngleSelect
          {...props}
          dashboard={DashboardConstants.CAM.value}
          type="gameAnglesNotShowns"
          field="feed"
          width={160}
          gamePk={gamePk}
          form={getForm(props.row.sequence)}
          dispatch={dispatchCamera}
          options={getFeedOptions(feedOptions, getForm(props?.row?.sequence)?.angle?.name)}
          getValue={primitiveToOption}
          saveApi={saveUnusedAngles}
          updateApi={updateGameAnglesNotShown}
          locked={locked}
        />
      ),
    },
  ];

  return (
    <DashboardWidgetWrapperNoGradient style={{ width: "600px", overflow: "hidden" }}>
      <>
        <Title>Angles Not Shown in MultiView</Title>
      </>
      <DashboardTable
        data={rows}
        columns={columns}
        rowsVisible={INPUTS}
        showToggle
        labelColWidth={20}
        tableWidth={600}
      />
    </DashboardWidgetWrapperNoGradient>
  );
};

export default AnglesNotShown;
