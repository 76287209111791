import React from "react";
import styled from "styled-components";

type PulsingStyleProps = {
  time: number;
  endOpacity: number;
  startOpacity: number;
};

const Pulsing = styled.div<PulsingStyleProps>`
  -webkit-animation: pulsate ${(props) => props.time}s ease-out;
  -webkit-animation-iteration-count: infinite;
  opacity: 0.5;
  height: 100%;
  width: 100%;

  @keyframes pulsate {
    0% {
      opacity: ${(props) => props.endOpacity};
    }
    50% {
      opacity: ${(props) => props.startOpacity};
    }
    100% {
      opacity: ${(props) => props.endOpacity};
    }
  }
`;

export type PulsingProps = {
  time?: number;
  endOpacity?: number;
  startOpacity?: number;
};

const Pulse: React.FC<React.PropsWithChildren<PulsingProps>> = ({
  time = 1.5,
  endOpacity = 0.5,
  startOpacity = 1,
  children,
}) => {
  return (
    <Pulsing endOpacity={endOpacity} time={time} startOpacity={startOpacity}>
      {children}
    </Pulsing>
  );
};

export default Pulse;
