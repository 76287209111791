import axios from "axios";
import { getPatch, Patch, PATCH_HEADERS } from "../modules/common/Patch";
import {
  GameAdditionalSsmo,
  GameAnglesNotShown,
  GameCameraInput,
  GameHfrSsmo,
  GameTech,
  GameSetting,
} from "../types/CamTypes";

const SERVICES_URL = window.MLBBest.envVariables.SERVICES_URL;
const API_URL = `${SERVICES_URL}/game/setting`;

export const saveGameCamera = (gci: GameCameraInput): Promise<GameCameraInput> =>
  axios.post(`${API_URL}/save/gameCamera`, gci).then(response => response.data);

export const updateGameCameraInput = (dashboard: string, id: number, patch: Patch[]): Promise<GameCameraInput> =>
  axios.patch(`${API_URL}/gameCamera/${dashboard}/${id}`, patch, PATCH_HEADERS).then(response => response.data);

export const saveGameHfrSsmo = (data: GameHfrSsmo): Promise<GameHfrSsmo> =>
  axios.post(`${API_URL}/save/hfrSsmo`, data).then(response => response.data);

export const updateGameHfrSsmo = (dashboard: string, id: number, patch: Patch[]): Promise<GameHfrSsmo> =>
  axios.patch(`${API_URL}/hfrSsmo/${dashboard}/${id}`, patch, PATCH_HEADERS).then(response => response.data);

export const updateGameHfrSsmoOrder = (gameHfrSsmos: GameHfrSsmo[]): Promise<GameHfrSsmo[]> =>
  axios.put(`${API_URL}/hfrSsmo/order`, gameHfrSsmos).then(response => response.data);

export const saveAdditionalSsmo = (data: GameAdditionalSsmo): Promise<GameAdditionalSsmo> =>
  axios.post(`${API_URL}/save/additionalSsmo`, data).then(response => response.data);

export const updateGameAdditionalSsmo = (dashboard: string, id: number, patch: Patch[]): Promise<GameAdditionalSsmo> =>
  axios.patch(`${API_URL}/additionalSsmo/${dashboard}/${id}`, patch, PATCH_HEADERS).then(response => response.data);

export const saveUnusedAngles = (data: GameAnglesNotShown): Promise<GameAnglesNotShown> =>
  axios.post(`${API_URL}/save/unusedAngles`, data).then(response => response.data);

export const updateGameAnglesNotShown = (dashboard: string, id: number, patch: Patch[]): Promise<GameAnglesNotShown> =>
  axios.patch(`${API_URL}/unusedAngles/${dashboard}/${id}`, patch, PATCH_HEADERS).then(response => response.data);

export const saveGameTech = (gameTech: Partial<GameTech>): Promise<GameTech> =>
  axios.post(`${API_URL}/save/gameTech/`, gameTech).then(response => response.data);

export const updateGameTech = (gameTechId: number, diff: Partial<GameTech>): Promise<GameTech> =>
  axios
    .patch(`${API_URL}/gameTech/${gameTechId}`, getPatch(true, "/tech", diff), PATCH_HEADERS)
    .then(response => response.data);

export const patchGameTech = (gameTechId: number, patch: Patch[]): Promise<GameTech> =>
  axios.patch(`${API_URL}/gameTech/${gameTechId}`, patch, PATCH_HEADERS).then(response => response.data);

export const updateGameSetting = (id: number, patch: Patch[]): Promise<GameSetting> =>
  axios.patch(`${API_URL}/gameSetting/${id}`, patch, PATCH_HEADERS).then(response => response.data);

export const saveGameSetting = (gameSetting: GameSetting): Promise<GameSetting> =>
  axios.post(`${API_URL}/save/gameSetting`, gameSetting).then(response => response.data);

export const deleteGameTechById = (id: number): Promise<string> =>
  axios.delete(`${API_URL}/gameTech/delete/${id}`).then(response => response.data);
