import ReactSelect from "react-select";
import styled from "styled-components";
import { SelectThemeType } from "../../../types";

type SelectStyleProps = {
  isDisabled: boolean;
  bcrStyles: SelectThemeType;
};

export const StyledSelect = styled(ReactSelect)<SelectStyleProps>`
  &&& {
    div {
      ${(props) => (props.isDisabled ? `background-color: ${props.bcrStyles.disabled.background}` : "")};
    }

    &.is-invalid {
      div {
        border-color: ${(props) => props.bcrStyles.required.borderColor} !important;
      }
    }
  }
`;

export const SelectContainer = styled.div`
  width: 100%;
`;
