import React from "react";
import { useTheme } from "../../../hooks";
import { ModalHeaderThemeType } from "../../../types";
import { deepMerge } from "../../../utils";
import { GenericModal, ModalHeaderProps } from "../../modal";

const PopoverHeader: React.FC<React.PropsWithChildren<ModalHeaderProps>> = ({ children, styles = {}, ...rest }) => {
  const { Theme } = useTheme();
  const StylesOverride: ModalHeaderThemeType = deepMerge<ModalHeaderThemeType>(Theme.popover.header, styles);

  return (
    <GenericModal.Header styles={StylesOverride} {...rest}>
      {children}
    </GenericModal.Header>
  );
};

PopoverHeader.displayName = "ModalHeader";

export default PopoverHeader;
