import clsx from "clsx";
import React from "react";
import { TextThemeType } from "../../types";
import { TextStyled } from "./TextStyles";

export interface TextElementProps {
  id?: string;
  className?: string;
  styles: TextThemeType;
}

const TextElement: React.FC<React.PropsWithChildren<TextElementProps>> = ({ children, styles, id, className = "" }) => (
  <TextStyled styles={styles} className={clsx(className)} id={id}>
    {children}
  </TextStyled>
);

export default TextElement;
