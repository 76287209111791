import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useTheme } from "../../../../hooks";
import { DataTableDataType, DatatableThemeType, FormatterStyleProps, FormatterType } from "../../../../types";
import { isElementOverflowed, isObject, typedMemo } from "../../../../utils";
import Tooltip from "../../../popover/tooltip/Tooltip";
import { formatterPropsCompare } from "../utils/TableUtils";

const TextHolder = styled.div<FormatterStyleProps>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.875rem;
  padding-right: 0.5rem;
  color: ${(props) => props.styles.row.color};
`;

const TextFormatter = <T extends DataTableDataType>(props: FormatterType<T>) => {
  const { rowIndex, value, className = "", styles = {}, alignCenter = true, onClick, row } = props;
  const { Theme } = useTheme();
  const ref = useRef<HTMLDivElement>(null);
  const [isOverflowed, setIsOverflowed] = useState<boolean>(false);
  const StylesOverride: DatatableThemeType = { ...Theme.datatable, ...styles };

  const getValue = (): React.ReactNode => (!!value && isObject(value) ? JSON.stringify(value) : value);

  useEffect(() => {
    if (!!ref?.current) {
      setIsOverflowed(isElementOverflowed(ref.current));
    }
  }, [ref]);

  return (
    <div
      className={clsx(className, "d-flex", "w-100", "h-100", { "align-items-center": alignCenter })}
      onClick={!!onClick ? () => onClick(rowIndex!, row) : undefined}
    >
      {isOverflowed && (
        <Tooltip ref={ref} trigger="hover" placement="bottom" delay={500} singleOpen={true}>
          {getValue()}
        </Tooltip>
      )}
      <TextHolder styles={StylesOverride} ref={ref}>
        {getValue()}
      </TextHolder>
    </div>
  );
};

const MemoTextFormatter = typedMemo(TextFormatter, formatterPropsCompare);

export default MemoTextFormatter;
