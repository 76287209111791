import React, { useCallback, useEffect, useRef, useState } from "react";
import { InfiniteLoader, InfiniteLoaderProps } from "react-virtualized";

/**
 * see also https://github.com/bvaughn/react-virtualized/blob/master/docs/InfiniteLoader.md
 */
export type DataTableInfiniteLoaderConfig = {
  /**
   * Callback to load rows up to the stopIndex (inclusive). this callback should ultimately populate the
   * data prop with the new rows so that its length is >= stopIndex
   **/
  loadMoreRows: (params: { stopIndex: number }) => void;
  /** Number of rows in list; can be arbitrary high number if actual number is unknown. **/
  rowCount: number;
  /**
   * Threshold at which to pre-fetch data. A threshold X means that data will start loading when a user scrolls within
   * X rows. Defaults to 15.
   **/
  threshold?: number;
  /**
   * Minimum number of rows to be loaded at a time.
   * This property can be used to batch requests to reduce HTTP requests. Defaults to 10
   */
  minimumBatchSize?: number;
  /**
   * Number of milliseconds to debounce calls to loadMoreRows. Helps prevent multiple calls to loadMoreRows when user
   * is still scrolling. Defaults to 200.
   */
  debounceMillis?: number;
  /**
   * Whether there was an error loading the data. If true, the infinite loader's state will be reset so scrolling up
   * and back to or past the rows that errored will cause a refetch.
   * */
  hasError?: boolean;
};

const useReactVirtualizedInfiniteLoaderProps = (
  infiniteLoader: DataTableInfiniteLoaderConfig,
  data: unknown[],
  ref: React.RefObject<InfiniteLoader | undefined>,
): Omit<InfiniteLoaderProps, "children"> => {
  const isRowLoaded = (params: { index: number }) => data?.length > params.index;
  const [maxRowScrolled, setMaxRowScrolled] = useState(0);
  const [maxRowRequested, setMaxRowRequested] = useState(0);
  const loadMoreRowsRef = useRef<{
    promiseCallbacks: { stopIndex: number; resolve: () => void }[];
    debounce?: () => void;
  }>({
    promiseCallbacks: [],
  });
  const loadMoreRows = useCallback(
    ({ stopIndex }: { stopIndex: number }) => {
      loadMoreRowsRef.current.debounce?.();
      const timeoutId = setTimeout(() => {
        setMaxRowScrolled((prev) => Math.max(prev, stopIndex));
      }, infiniteLoader.debounceMillis ?? 200);
      loadMoreRowsRef.current.debounce = () => clearTimeout(timeoutId);
      return new Promise((resolve) => {
        loadMoreRowsRef.current.promiseCallbacks.push({
          stopIndex,
          resolve: () => resolve(undefined),
        });
      });
    },
    [infiniteLoader.debounceMillis],
  );
  useEffect(() => {
    const isLoaded = data.length >= maxRowScrolled;
    const isLoading = data.length < maxRowRequested;
    if (!isLoaded && !isLoading) {
      setMaxRowRequested(maxRowScrolled);
      infiniteLoader.loadMoreRows({ stopIndex: maxRowScrolled });
    } else if (isLoading && infiniteLoader.hasError) {
      setMaxRowScrolled(0);
      setMaxRowRequested(0);
      ref.current?.resetLoadMoreRowsCache(false);
    }

    loadMoreRowsRef.current.promiseCallbacks = loadMoreRowsRef.current.promiseCallbacks.filter(
      ({ stopIndex, resolve }) => {
        if (stopIndex <= data.length) {
          resolve();
          return false;
        }
        return true;
      },
    );
  }, [data.length, maxRowScrolled, maxRowRequested, infiniteLoader.loadMoreRows, infiniteLoader.hasError]);

  return {
    isRowLoaded,
    loadMoreRows,
    rowCount: infiniteLoader.rowCount,
    threshold: infiniteLoader.threshold,
    minimumBatchSize: infiniteLoader.minimumBatchSize,
  };
};

type DataTableLoaderProps = DataTableInfiniteLoaderConfig & {
  data: unknown[];
  children: InfiniteLoaderProps["children"];
};

const DataTableInfiniteLoader: React.FC<DataTableLoaderProps> = ({ data, children, ...infiniteLoader }) => {
  const ref = useRef<InfiniteLoader>();
  const { isRowLoaded, loadMoreRows } = useReactVirtualizedInfiniteLoaderProps(infiniteLoader, data, ref);

  return (
    <InfiniteLoader
      ref={(_ref) => {
        ref.current = _ref || undefined;
      }}
      loadMoreRows={loadMoreRows}
      isRowLoaded={isRowLoaded}
      rowCount={infiniteLoader.rowCount}
      threshold={infiniteLoader.threshold}
      minimumBatchSize={infiniteLoader.minimumBatchSize}
    >
      {children}
    </InfiniteLoader>
  );
};

export default DataTableInfiniteLoader;
