import React from "react";
import ValidationMessage, { ValidationMessageType } from "./ValidationMessage";

type ValidationMessagesProps = {
  /** validation messages to show */
  validationMessages?: ValidationMessageType[];
};

const ValidationMessages: React.FC<React.PropsWithChildren<ValidationMessagesProps>> = ({ validationMessages }) => {
  return (
    <>
      {validationMessages?.length &&
        validationMessages.map((v: ValidationMessageType, index: number) => (
          <ValidationMessage
            type={v.type}
            message={v.message}
            // eslint-disable-next-line
            key={index}
            validationFunction={v.validationFunction}
          />
        ))}
    </>
  );
};

export default ValidationMessages;
