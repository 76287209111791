import React, { useRef, useState } from "react";
import { ActionType, DataTableDataType, DatatableThemeType, ModalBodyThemeType, ModalThemeType } from "types";
import { useTheme } from "../../../../hooks";
import Icon from "../../../icon/Icon";
import Popover from "../../../popover/popover/Popover";
import { useDataTable } from "../DataTableContext";
import { MoreActionsButtonStyles, MoreActionsPopoverAction } from "../DatatableStyles";

type MoreActionsButtonProps<T extends DataTableDataType> = {
  data: T;
  loading: boolean;
  disabled?: boolean;
  styles?: DatatableThemeType;
};

const MoreActionsButton = <T extends DataTableDataType>(props: MoreActionsButtonProps<T>) => {
  const { actions } = useDataTable();
  const { data, disabled = false, styles = { datatable: {} }, loading } = props;
  const [open, setOpen] = useState(false);
  const { Theme } = useTheme();
  const datatableStylesOverride: DatatableThemeType = {
    ...Theme.datatable,
    ...styles?.datatable,
  };
  const modalBodyStylesOverride: ModalBodyThemeType = {
    ...Theme.modal.body,
    background: Theme.colors.cloud,
    padding: "0",
  };

  const modalStylesOverride: ModalThemeType = {
    ...Theme.modal,
    border: `solid; border-width: 0px 0px 2px 0px; border-color: ${datatableStylesOverride.moreActions.popoverAction.borderColor}`,
  };

  const iconButtonRef = useRef(null);

  return !!actions ? (
    <MoreActionsButtonStyles>
      {!loading && (
        <>
          <Popover
            ref={iconButtonRef}
            trigger="click"
            placement={actions?.location}
            disabled={disabled}
            styles={modalStylesOverride}
            setIsOpen={setOpen}
            singleOpen={actions?.singleOpen}
          >
            <Popover.Body styles={modalBodyStylesOverride}>
              {actions?.actions.map((a: ActionType<T>) => (
                <MoreActionsPopoverAction
                  onClick={() => a.onClick(data)}
                  $styles={datatableStylesOverride}
                  key={`table-action-${a.name}`}
                >
                  {a.name}
                </MoreActionsPopoverAction>
              ))}
            </Popover.Body>
          </Popover>
          <div ref={iconButtonRef} style={{ width: "100%", textAlign: "center" }}>
            <Icon
              iconName={open ? "fa-times-circle" : actions?.icon ? actions?.icon : "fa-ellipsis-v"}
              styles={
                disabled
                  ? {
                      color: datatableStylesOverride.moreActions.icon.disabledColor,
                      hoverColor: datatableStylesOverride.moreActions.icon.disabledColor,
                    }
                  : {
                      color: datatableStylesOverride.moreActions.icon.color,
                      hoverColor: datatableStylesOverride.moreActions.icon.hoverColor,
                    }
              }
            />
          </div>
        </>
      )}
    </MoreActionsButtonStyles>
  ) : null;
};

export default MoreActionsButton;
