import styled from "styled-components";
import { ValidationThemeType } from "../../types";

type ValidationStylesProps = {
  type: "success" | "error";
  isInvalid: boolean;
  styles: ValidationThemeType;
};

export const ValidationMessageStyled = styled.span.attrs(() => ({
  className: "bcr-validation-message",
}))<ValidationStylesProps>`
  display: ${(props) => (props.isInvalid ? "inline" : "none")};
  color: ${(props) => props.styles[props.type].color};
  font-size: 0.75rem;
  font-style: italic;
  margin-bottom: 0;
  flex: 2;
  text-align: right;
`;
