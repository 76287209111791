import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { AutoSizer, CellMeasurer, CellMeasurerCache, List } from "react-virtualized";
import styled from "styled-components";
import { Typography } from "../../layout";
import { DataTableDataType } from "../../types";
import { renderComponent } from "../../utils";
import { Loader } from "../loading";
import { useDataTable } from "../table/datatable/DataTableContext";
import { RowRendererProps } from "../table/datatable/types/DataTableComponentTypes";

type MobileAccordionStyleProps = {
  height?: number;
  noScroll?: boolean;
};

const Holder = styled.div`
  position: relative;

  *:focus {
    outline: none;
  }
`;

const SearchResultsHolder = styled.div<MobileAccordionStyleProps>`
  overflow: visible;
  height: ${(props) => (!!props.noScroll ? "100%" : `${props.height}px`)};
`;

type OpenAccordionsType = { [index: number]: boolean };

const MobileAccordions = <MobileAccordingDataType extends DataTableDataType>() => {
  const {
    type,
    heights: { bodyHeight, noScroll },
    data,
    accordion: { accordion },
    scroll: { scrollToIndex },
    pagination: { setOnPaginationChanged },
    noDataMessage = "",
    loading,
  } = useDataTable<MobileAccordingDataType>();
  const isVirtualized = useMemo(() => type === "virtualized", [type]);
  const list = useRef<List>();
  const [openAccordions, setOpenAccordions] = useState<OpenAccordionsType>({});
  const [cellMeasurerCache, setCellMeasurerCache] = useState<CellMeasurerCache | undefined>(undefined);

  const recompute = useCallback(
    (index?: number) => {
      setTimeout(() => {
        if (!!cellMeasurerCache) {
          if (typeof index != "undefined" && index > -1) {
            cellMeasurerCache.clear(index, 0);
          }
        }
        if (!!list?.current?.recomputeRowHeights) {
          list!.current!.recomputeRowHeights(index);
        }
      }, 10);
    },
    [list, cellMeasurerCache],
  );

  const accordionChanged = (index: number, newState: boolean) => {
    setOpenAccordions((existing) => {
      existing[index] = newState;
      return existing;
    });
    recompute(index);
  };

  const RowRenderer: React.FC<React.PropsWithChildren<RowRendererProps>> = useCallback(
    ({ index, key, style, parent }) => (
      <CellMeasurer key={key} cache={cellMeasurerCache!} parent={parent} columnIndex={0} rowIndex={index}>
        <div className="pb-2" key={index} style={style}>
          {renderComponent(accordion, {
            data: data[index],
            index,
            accordionKey: `mobile-accordion-${index}`,
            startOpen: openAccordions[index],
            onClick: (newState: boolean) => accordionChanged(index, newState),
            animation: false,
          })}
        </div>
      </CellMeasurer>
    ),
    [cellMeasurerCache, data, openAccordions],
  );

  const paginationChanged = useCallback(() => {
    if (!!list?.current?.scrollToRow) {
      list.current.scrollToRow(0);
    }
  }, [list]);

  useEffect(() => {
    setCellMeasurerCache(
      new CellMeasurerCache({
        fixedWidth: true,
        defaultHeight: 100,
      }),
    );
  }, [isVirtualized]);

  useEffect(() => {
    setOnPaginationChanged(paginationChanged);
  }, [paginationChanged]);

  return (
    <Holder>
      {loading ? (
        <div className="w-100 h-100 d-flex justify-content-center align-items-center">
          <Loader size="50px" type="spinner" />
        </div>
      ) : !data?.length && !!noDataMessage?.length ? (
        <div className="d-flex justify-content-center align-items-center py-3">
          <Typography variant="div" className="text-center">
            {noDataMessage}
          </Typography>
        </div>
      ) : (
        <SearchResultsHolder noScroll={noScroll} height={bodyHeight}>
          {isVirtualized && cellMeasurerCache ? (
            <>
              {!!data?.length && (
                <AutoSizer>
                  {({ height, width }) => {
                    return (
                      <List
                        //@ts-ignore
                        ref={list}
                        width={width}
                        height={height}
                        rowCount={data.length}
                        rowHeight={cellMeasurerCache!.rowHeight}
                        rowRenderer={RowRenderer}
                        scrollToIndex={scrollToIndex}
                      />
                    );
                  }}
                </AutoSizer>
              )}
            </>
          ) : (
            <>
              {data.map((d: MobileAccordingDataType, index: number) => (
                <div className="mb-2" key={d.id || index}>
                  {renderComponent(accordion, {
                    data: d,
                    index,
                    accordionKey: `mobile-accordion-${index}`,
                    startOpen: openAccordions[index],
                    onClick: (newState: boolean) => accordionChanged(index, newState),
                    animation: false,
                  })}
                </div>
              ))}
            </>
          )}
        </SearchResultsHolder>
      )}
    </Holder>
  );
};

export default MobileAccordions;
