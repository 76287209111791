import { CountryCode, PhoneNumber } from "libphonenumber-js/types";
import React, { useEffect, useState } from "react";
import { Typography } from "../../../layout";
import { constructPhoneNumber } from "../../../utils";

type PhoneNumberDisplayProps = {
  id: string;
  phoneNumber?: string;
  countryCode?: CountryCode;
  className?: string;
  onClick?: (event: React.MouseEvent) => void;
};

const PhoneNumberDisplay: React.FC<React.PropsWithChildren<PhoneNumberDisplayProps>> = React.memo(
  ({ id, phoneNumber = "", countryCode = "US", className = "", onClick }) => {
    const [phoneNumberValue, setPhoneNumberValue] = useState<PhoneNumber | null>(null);

    useEffect(() => {
      setPhoneNumberValue(constructPhoneNumber(phoneNumber, countryCode));
    }, [phoneNumber, countryCode]);

    return (
      <Typography id={id} variant="span" className={className} onClick={onClick}>
        {!!phoneNumberValue ? phoneNumberValue.formatNational() : ""}
      </Typography>
    );
  },
);

export default PhoneNumberDisplay;
