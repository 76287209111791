import React, { useEffect, useState } from "react";
import { DashboardWidgetWrapper } from "../../DashboardStyledComponents";
import { clearSFGameAngleSettings, setDefaultSFGameAngleSetting } from "../../../../api/CamApi";
import SetDefaultModal from "./SetDefaultModal";
import { useAlert } from "../../../../contexts/AlertsContext";
import { AlertConstants, Button } from "best-common-react";
import styled from "styled-components";
import { CameraAction, useCamera } from "../../../../contexts/CameraContext";

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
`;
const ClearText = styled.div`
  margin-top: 0.5rem;
  margin-left: 5rem;
  cursor: pointer;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
  font-size: 11px;
  font-weight: bold;
  color: ${(props): string => props.theme.colors["mlb-red"]};
`;

const SetDefault: React.FC = () => {
  const showAlert = useAlert();
  const { cameraState, dispatchCamera } = useCamera();
  const [defaultState, setDefaultState] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const selectedGame = cameraState?.selectedGame;
  const locked = cameraState?.camGameSettings?.filter(game => game?.gameSetting?.sfLock).map(g => g.gamePk);

  const setDefault = () => {
    setDefaultSFGameAngleSetting(selectedGame?.gamePk)
      .then(() => {
        setDefaultState(true);
        showAlert("Default Saved", AlertConstants.TYPES.SUCCESS);
      })
      .catch(error => {
        showAlert("Error setting Default ", AlertConstants.TYPES.DANGER);
        console.log(error);
      });
  };

  const clearAll = () => {
    clearSFGameAngleSettings(selectedGame?.gamePk)
      .then(() => {
        setDefaultState(true);
        dispatchCamera({
          type: CameraAction.CLEAR_ANGLES,
        });
        showAlert("Successfully Cleared All", AlertConstants.TYPES.SUCCESS);
      })
      .catch(error => {
        showAlert("Error Clearing", AlertConstants.TYPES.DANGER);
        console.log(error);
      });
  };

  useEffect(() => {
    setDefaultState(false);
  }, [selectedGame]);

  return (
    <>
      <SetDefaultModal
        show={showModal}
        hide={() => setShowModal(false)}
        setDefault={() => setDefault()}
        orgAbbrv={selectedGame?.home?.name}
      />
      <DashboardWidgetWrapper>
        <Wrapper>
          <Button disabled={defaultState} onClick={() => setShowModal(true)}>
            Set Default
          </Button>
          {!locked.includes(selectedGame?.gamePk) && <ClearText onClick={() => clearAll()}> Clear All</ClearText>}
        </Wrapper>
      </DashboardWidgetWrapper>
    </>
  );
};

export default SetDefault;
