import React, { createContext, useContext, useState } from "react";
import { AlertConstants, AlertType, AlertTypes } from "best-common-react";

type AlertsContext = {
  alerts: Array<AlertType>;
  addAlert: (alert: AlertType) => void;
  removeAlert: (id: number) => void;
  addAlerts: (alerts: Array<AlertType>) => void;
};

const AlertsContext = createContext<AlertsContext>({
  alerts: [],
  addAlert: null,
  removeAlert: null,
  addAlerts: null,
});

const getAlertId = (): string => {
  return (
    "_" +
    Math.random()
      .toString(36)
      .substr(2, 9)
  );
};

const AlertsProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [alerts, setAlerts] = useState([]);

  const addAlert = (alert: AlertType): void => {
    const newAlerts = [...alerts];
    if (!alert.id) {
      alert.id = getAlertId();
    }
    newAlerts.push(alert);
    setAlerts(newAlerts);
    if (alert.type === AlertConstants.TYPES.SUCCESS) {
      setTimeout(() => {
        removeAlert(alert.id);
      }, 3000);
    }
  };

  const addAlerts = (alerts: Array<AlertType>): void => {
    const newAlerts: Array<AlertType> = [];
    alerts.forEach(alert => {
      if (!alert.id) {
        alert.id = getAlertId();
      }
      newAlerts.push(alert);
    });
    setAlerts(newAlerts);
  };

  const removeAlert = (id: number | string): void => {
    if (id) {
      const newAlerts = alerts.filter(alert => alert.id !== id);
      setAlerts(newAlerts);
    }
  };

  return (
    <AlertsContext.Provider value={{ alerts, addAlert, removeAlert, addAlerts }}>{children}</AlertsContext.Provider>
  );
};

const useAlertContext = (): AlertsContext => {
  const context = useContext<AlertsContext>(AlertsContext);
  if (context === undefined) {
    throw new Error(`useAlertContext must be used within a AlertsContextProvider`);
  }
  return context;
};

const useAlert = (): ((message: string, messageType: AlertTypes) => void) => {
  const alertContext = useContext(AlertsContext);
  const { addAlert } = alertContext;
  return (message: string, messageType: AlertTypes = AlertConstants.TYPES.SUCCESS): void => {
    addAlert({
      id: null,
      type: messageType,
      text: message,
    });
  };
};

const useAlerts = (): ((alerts: Array<AlertType>) => void) => {
  const alertContext = useContext(AlertsContext);
  const { addAlerts } = alertContext;

  return (alerts: Array<AlertType>): void => {
    const alertsToAdd = alerts.map(alert => {
      return { id: null, type: alert.type, text: alert.text };
    });
    addAlerts(alertsToAdd);
  };
};

export { AlertsContext, AlertsProvider, useAlert, useAlertContext, useAlerts };
