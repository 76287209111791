import { Checkbox, Icon } from "best-common-react";
import React, { useEffect, useState } from "react";
import { getSFGameSetting, saveSFGameSetting } from "../../../../api/CamApi";
import { CameraAction, useCamera } from "../../../../contexts/CameraContext";
import { GameSetting, GameSettingDTO } from "../../../../types/CamTypes";
import { FlexRow } from "../../../common/CommonStyledComponents";
import {
  ActiveIcon,
  DashboardWidgetWrapper,
  Selector,
  StatusIcon,
  Title,
  getStatusColorTypeToggle,
} from "../../DashboardStyledComponents";
import { getGameInfo } from "./CameraView";
import { useSseNotification } from "../../../../contexts/SseNotificationContext";
import { ReviewStatuses } from "../../../../constants/ReviewDetailsConstants";

const Games: React.FC = () => {
  const { notification } = useSseNotification();
  const { cameraState, dispatchCamera } = useCamera();
  const [gameSettings, setGameSettings] = useState<Record<number, GameSetting>>({});

  useEffect(() => {
    const settings: Record<number, GameSetting> = cameraState.camGameSettings.reduce(
      (group: Record<number, GameSetting>, gameSettingDTO: GameSettingDTO) => {
        const gamePk = gameSettingDTO.gamePk;
        group[gamePk] = gameSettingDTO.gameSetting;
        return group;
      },
      {},
    );

    setGameSettings(settings);
  }, [cameraState.camGameSettings]);

  useEffect(() => {
    const settingGamePks = cameraState.camGameSettings?.map((s: GameSettingDTO) => s.gamePk);
    const notificationGamePk = notification.gameSetting?.data?.gamePk;

    if (cameraState.selectedGame?.gamePk === notificationGamePk) {
      const game = cameraState.selectedGame;
      if (game) {
        getSFGameSetting(
          game.gameDt
            .split("")
            .map((c: string): string => (c === "/" ? "-" : c))
            .join(""),
          game.home.orgId.toString(),
          game.gamePk.toString(),
        ).then(response => {
          dispatchCamera({
            type: CameraAction.LOAD_SERIES,
            payload: { series: response, selectedGame: game },
          });
        });
      }
    } else if (settingGamePks?.includes(notificationGamePk)) {
      dispatchCamera({
        type: CameraAction.UPDATE_SETTING,
        payload: { gamePk: notificationGamePk, gameSetting: notification.gameSetting.data },
      });
    }
  }, [notification.gameSetting?.lastUpdated]);

  return (
    <DashboardWidgetWrapper style={{ minWidth: "300px" }}>
      <>
        <Title>Games</Title>
      </>
      {cameraState.gameSchedule.length > 0 &&
        cameraState.gameSchedule.map(game => {
          const selected = game.gamePk === cameraState?.selectedGame?.gamePk && game.status !== "PPD";
          // filter out any instances where a game is ppd but then played again in the same series
          if (game.status === "PPD" && cameraState.gameSchedule.filter(g => g.gamePk === game.gamePk).length > 1) {
            return null;
          }
          const gameSetting: GameSetting = gameSettings[game.gamePk];

          return (
            <FlexRow key={game.gamePk}>
              <FlexRow style={{ alignItems: "center" }}>
                {game.status !== "PPD" && (
                  <>
                    {gameSetting?.sfLock ? (
                      <Icon styles={{ size: "1rem" }} iconName="fa-lock" />
                    ) : (
                      <div style={{ marginTop: "-10px" }}>
                        <Checkbox
                          id={`${game.gamePk}statusCheckbox`}
                          checked={gameSetting?.sfStatus === ReviewStatuses.COMPLETED}
                          onChange={() => {
                            saveSFGameSetting({
                              ...gameSetting,
                              gamePk: game.gamePk,
                              sfStatus:
                                gameSetting?.sfStatus === ReviewStatuses.COMPLETED
                                  ? ReviewStatuses.OPEN
                                  : ReviewStatuses.COMPLETED,
                            }).then(response => {
                              setGameSettings({ ...gameSettings, [game.gamePk]: response });
                            });
                          }}
                        />
                      </div>
                    )}
                    <StatusIcon color={getStatusColorTypeToggle(gameSetting?.sfStatus === ReviewStatuses.COMPLETED)} />
                  </>
                )}
              </FlexRow>
              <div style={{ width: "215px", marginLeft: game.status === "PPD" ? "38px" : "0px" }}>
                <Selector
                  selected={selected}
                  onClick={() => {
                    getSFGameSetting(
                      game.gameDt
                        .split("")
                        .map((c: string): string => (c === "/" ? "-" : c))
                        .join(""),
                      game.home.orgId.toString(),
                      game.gamePk.toString(),
                    ).then(response => {
                      dispatchCamera({
                        type: CameraAction.LOAD_SERIES,
                        payload: { series: response, selectedGame: game },
                      });
                    });
                    localStorage.setItem("camSelectedGame", JSON.stringify(game));
                  }}
                >
                  <FlexRow style={{ justifyContent: "space-between" }}>
                    <div>{getGameInfo(game)}</div>
                    <ActiveIcon />
                  </FlexRow>
                </Selector>
              </div>
            </FlexRow>
          );
        })}
    </DashboardWidgetWrapper>
  );
};

export default Games;
