import React from "react";
import { useTheme } from "../../../hooks";
import { ModalBodyThemeType } from "../../../types";
import { deepMerge } from "../../../utils";
import { GenericModal, ModalBodyProps } from "../../modal";

const PopoverBody: React.FC<React.PropsWithChildren<ModalBodyProps>> = ({ children, styles = {}, ...rest }) => {
  const { Theme } = useTheme();
  const StylesOverride: ModalBodyThemeType = deepMerge<ModalBodyThemeType>(Theme.popover.body, styles);

  return (
    <GenericModal.Body styles={StylesOverride} {...rest}>
      {children}
    </GenericModal.Body>
  );
};

PopoverBody.displayName = "ModalBody";

export default PopoverBody;
