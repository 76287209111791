import React from "react";
import { SortDirection } from "react-virtualized";
import { AccordionProps } from "../components";
import { DateInputProps, DateRangeInputProps, InputProps, SelectProps, TextAreaProps } from "../form";
import { AcceptedDateTypes, DatatableThemeType } from "../types";
import { FormatterType } from "./FormatterTypes";
import { PopoverPlacement } from "./PopoverTypes";

export type DataTableType = "virtualized" | "not-virtualized";

export type DataTableDataType = {
  id?: string | number;
  subRows?: any[];
  expanded?: boolean;
};

export type DataTableInputProps = Omit<InputProps, "id" | "onChange" | "value">;
export type DataTableSelectProps<A, B, C> = Omit<SelectProps<A, B, C>, "id" | "onChange" | "value">;
export type DataTableTextAreaProps = Omit<TextAreaProps, "id" | "onChange" | "value">;
export type DataTableDateInputProps<A extends AcceptedDateTypes> = Omit<DateInputProps<A>, "id" | "onChange" | "value">;
export type DataTableDateRangeInputProps<A extends AcceptedDateTypes> = Omit<
  DateRangeInputProps<A>,
  "id" | "onChange" | "value"
>;

export type DataTableAllInputProps =
  | DataTableInputProps
  | DataTableSelectProps<any, any, any>
  | DataTableTextAreaProps
  | DataTableDateInputProps<any>
  | DataTableDateRangeInputProps<any>;

export type DataTableColumn<T extends DataTableDataType> = {
  name: React.ReactNode;
  key: string;
  width?: number;
  minWidth?: number;
  fixed?: boolean;
  sortable?: boolean;
  cellClass?: string;
  headerBackground?: string;
  readonlyFormatter?: React.FC<React.PropsWithChildren<FormatterType<T>>>;
  editFormatter?: React.FC<React.PropsWithChildren<FormatterType<T>>>;
  onClick?: (index: number, value?: T) => void;
  icon?: string;
  visible?: boolean;
  type?: "view" | "input"; //todo: add support for more types
  onChange?: <K extends keyof T, V extends T[K]>(index: number, key: K, value: V) => void;
  inputProps?: DataTableAllInputProps | ((row: T) => DataTableAllInputProps);
};

export type DataTableRef = {
  toggleRowExpansion: (value: boolean) => void;
};

export type ActionType<T extends DataTableDataType> = {
  name: React.ReactNode;
  onClick: (data: T) => void;
};

export type ActionProps<T extends DataTableDataType> = {
  actions: ActionType<T>[];
  singleOpen?: boolean;
  icon?: string;
  location?: PopoverPlacement;
};

export type SortDirection = "ASC" | "DESC" | "NO DIRECTION";

export const ASC: SortDirection = "ASC" as SortDirection;
export const DESC: SortDirection = "DESC" as SortDirection;
export const NO_DIRECTION: SortDirection = "NONE" as SortDirection;

export type SortFilters<T> = {
  key: keyof T;
  direction: SortDirection;
};

export type ExpansionType<T extends DataTableDataType> = {
  value: T;
  styles?: DatatableThemeType;
};

export type RowSelection<T extends DataTableDataType> = {
  showCheckbox: boolean;
  onRowsSelected: (rows: T[]) => void;
  onRowsDeselected: (rows: T[]) => void;
  selectBy: {
    isSelectedKey: string;
  };
  canSelect: () => boolean;
};

export type RowSelectionV2 = {
  isSelected: (rowIndex: number) => boolean;
  isSelectionDisabled?: (rowIndex: number) => boolean;
  onSelectionChange: (rowIndexes: number[], selected: boolean) => void;
};

export type RowExpansion<T extends DataTableDataType> = {
  expandable: boolean;
  expandAllCallback: (expanded: object) => void;
  expandCallback: (index: number, value: T, expanded: boolean) => void;
  expandedKey: string;
};

export type RowClick<T extends DataTableDataType> = {
  onClick: (index: number, value: T) => void;
  isClickable?: (index: number, value: T) => boolean;
};

export type CustomMobileAccordionType<MobileAccordingDataType extends DataTableDataType> = AccordionProps & {
  data: MobileAccordingDataType;
  index: number;
};
