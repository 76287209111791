import { DataTableColumn, FormatterType, ValueOpt } from "best-common-react";
import React from "react";
import { saveGameHfrSsmo, updateGameHfrSsmo } from "../../../../api/GameSettingApi";
import {
  primitivesToOptions,
  lookupsToOptions,
  lookupToOption,
  primitiveToOption,
} from "../../../../contexts/DropdownContext";
import { useCamera } from "../../../../contexts/CameraContext";
import { GameHfrSsmo } from "../../../../types/CamTypes";
import DashboardTable from "../../common/DashboardTable";
import { DashboardWidgetWrapperNoGradient, Title } from "../../DashboardStyledComponents";
import { AngleSelect, getFeedOptions } from "../../common/AngleSelect";
import { DashboardConstants } from "../../../../constants/DashboardConstants";

const INPUTS = 4;
const EXPANDED_INPUTS = 4;
const ISO_COUNT = 24;

const HFRsTaken: React.FC = () => {
  const { cameraState, dispatchCamera } = useCamera();
  const { selectedGameForm } = cameraState;
  const { gameHfrSsmos } = selectedGameForm;
  const locked = selectedGameForm?.gameSetting?.sfLock;

  const getForm = (seq: number): GameHfrSsmo => {
    return gameHfrSsmos?.find((x: GameHfrSsmo): boolean => seq === x.sequence);
  };

  const angleOptions: ValueOpt<any>[] = lookupsToOptions(cameraState.angles);
  const inputOptions: ValueOpt<any>[] = primitivesToOptions(
    [...Array(ISO_COUNT).keys()].map((i: number): string => `ISO ${i + 1}`),
  );
  const feedOptions: ValueOpt<any>[] = primitivesToOptions(cameraState.feeds);

  const gamePk: number = cameraState?.selectedGame?.gamePk;

  const rows = [...Array(INPUTS + EXPANDED_INPUTS).keys()].map((i: number) => {
    return {
      label: "HFR",
      sequence: i + 1,
    };
  });

  const columns: DataTableColumn<any>[] = [
    {
      name: "Camera Angle",
      key: "field",
      width: 210,
      editFormatter: (props: FormatterType<any>) => (
        <AngleSelect
          {...props}
          dashboard={DashboardConstants.CAM.value}
          type="gameHfrSsmos"
          field="angle"
          width={210}
          gamePk={gamePk}
          form={getForm(props.row.sequence)}
          dispatch={dispatchCamera}
          options={angleOptions}
          getValue={lookupToOption}
          saveApi={saveGameHfrSsmo}
          updateApi={updateGameHfrSsmo}
          locked={locked}
        />
      ),
    },
    {
      name: "Feed",
      key: "feed",
      width: 180,
      editFormatter: (props: FormatterType<any>) => (
        <AngleSelect
          {...props}
          dashboard={DashboardConstants.CAM.value}
          type="gameHfrSsmos"
          field="feed"
          width={180}
          gamePk={gamePk}
          form={getForm(props.row.sequence)}
          dispatch={dispatchCamera}
          options={getFeedOptions(feedOptions, getForm(props?.row?.sequence)?.angle?.name)}
          getValue={primitiveToOption}
          saveApi={saveGameHfrSsmo}
          updateApi={updateGameHfrSsmo}
          locked={locked}
        />
      ),
    },
    {
      name: "Input",
      key: "input",
      width: 120,
      editFormatter: (props: FormatterType<any>) => (
        <AngleSelect
          {...props}
          dashboard={DashboardConstants.CAM.value}
          type="gameHfrSsmos"
          field="input"
          width={120}
          gamePk={gamePk}
          form={getForm(props.row.sequence)}
          dispatch={dispatchCamera}
          options={inputOptions}
          getValue={primitiveToOption}
          saveApi={saveGameHfrSsmo}
          updateApi={updateGameHfrSsmo}
          locked={locked}
        />
      ),
    },
  ];

  return (
    <DashboardWidgetWrapperNoGradient>
      <>
        <Title>HFRs Taken</Title>
      </>
      <DashboardTable label="HFR" data={rows} columns={columns} rowsVisible={INPUTS} showToggle />
    </DashboardWidgetWrapperNoGradient>
  );
};

export default HFRsTaken;
