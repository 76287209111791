import { GenericAction } from "../../types/ReducerTypes";

export enum PageLayoutActionType {
  SetPageLayoutRef = "SET_PAGE_LAYOUT_REF",
}

export interface SetPageLayoutRef extends GenericAction {
  type: PageLayoutActionType.SetPageLayoutRef;
  payload: { ref: HTMLDivElement };
}

export type PageLayoutActions = SetPageLayoutRef;
