import { Label, PhoneNumberDisplay, Select, ValueOpt } from "best-common-react";
import React from "react";
import styled from "styled-components";
import { deleteGameTechById, saveGameTech, updateGameTech } from "../../../../api/GameSettingApi";
import { getGameTechTypes } from "../../../../constants/GameTechConstants";
import { lookupToOption } from "../../../../contexts/DropdownContext";
import { CameraAction, useCamera } from "../../../../contexts/CameraContext";
import { ScheduleDTO } from "../../../../types/DailyAssignmentTypes";
import { Tech } from "../../../../types/SettingsTypes";
import { GameTech } from "../../../../types/CamTypes";
import { FlexRow } from "../../../common/CommonStyledComponents";
import { DashboardWidgetWrapper, Title, TableValue } from "../../DashboardStyledComponents";

const TechLabel = styled.div`
  width: 85px;
  display: flex;
  align-items: center;
`;

const nameStyle = { width: "220px", marginRight: "1rem" };

const getFullName = (tech: Tech): string => {
  if (!tech) return "";
  return `${tech.lastName}, ${tech.name}`;
};

const TechInformation: React.FC = () => {
  const { cameraState, dispatchCamera } = useCamera();

  const gameTechs: GameTech[] = cameraState.selectedGameForm.gameTechs;

  const getTechByType = (type: string) => {
    return gameTechs?.find((gt: GameTech): boolean => gt.type === type);
  };

  const selectedGamePk: number = cameraState?.selectedGame?.gamePk;
  const isPlayoffGame: boolean = ["D", "F", "L", "W"].includes(
    cameraState?.gameSchedule.find((dto: ScheduleDTO): boolean => dto.gamePk === selectedGamePk)?.gameType,
  );

  const techOptions: ValueOpt<any>[] = cameraState.techs
    .map(
      (tech: Tech): ValueOpt<any> => {
        return {
          id: tech.id,
          label: getFullName(tech),
          value: tech,
        };
      },
    )
    .sort((a, b) => a.value.lastName.localeCompare(b.value.lastName));

  const onChangeHandler = (selected: ValueOpt<any>, type: string, tech: GameTech) => {
    if (!selected) {
      deleteGameTech(tech);
    } else if (tech && tech?.id) {
      update(tech, selected);
    } else {
      save(selected, type);
    }
  };

  const save = (selected: ValueOpt<any>, type: string): void => {
    saveGameTech({ tech: selected?.value, type: type, gamePk: selectedGamePk }).then(response => {
      dispatchCamera({ type: CameraAction.UPDATE_TECHS, payload: response });
    });
  };

  const update = (tech: GameTech, selected: ValueOpt<any>): void => {
    updateGameTech(tech.id, selected?.value).then(response => {
      dispatchCamera({ type: CameraAction.UPDATE_TECHS, payload: response });
    });
  };

  const deleteGameTech = (tech: GameTech): void => {
    deleteGameTechById(tech.id).then(() => {
      dispatchCamera({ type: CameraAction.UPDATE_TECHS, payload: { type: tech.type } });
    });
  };

  if (!selectedGamePk) return null;

  return (
    <DashboardWidgetWrapper>
      <FlexRow>
        <Title style={{ width: "85px" }}>Tech</Title>
        <Title style={nameStyle}>Name</Title>
        <Title style={{ width: "150px" }}>Phone</Title>
      </FlexRow>

      {getGameTechTypes(isPlayoffGame).map(
        (type: string): JSX.Element => {
          const gameTech: GameTech = getTechByType(type);
          return (
            <FlexRow key={type} style={{ marginBottom: "0.25rem" }}>
              <TechLabel>
                <Label htmlFor={`${type}-select`}>{type}</Label>
              </TechLabel>
              <div style={nameStyle}>
                <Select
                  id={`${type}-select`}
                  placeholder=""
                  clearable={Boolean(gameTech)}
                  options={techOptions}
                  onChange={(selected: ValueOpt<any>) => onChangeHandler(selected, type, gameTech)}
                  value={lookupToOption({ ...gameTech?.tech, name: getFullName(gameTech?.tech) })}
                />
              </div>
              <TableValue>
                <PhoneNumberDisplay id={`${type}-phone`} phoneNumber={gameTech?.tech?.phoneNumber} />
              </TableValue>
            </FlexRow>
          );
        },
      )}
    </DashboardWidgetWrapper>
  );
};

export default TechInformation;
