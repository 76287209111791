import { Link } from "best-common-react";
import styled from "styled-components";

export const ModalBodyText = styled.div`
  color: ${props => props.theme.modal.body.fontColor};
  font-size: 0.87rem;
`;

export const ModalBodyHintText = styled.div`
  color: ${props => props.theme.colors.slate};
  font-size: 0.6875rem;
  font-style: italic;
  margin-bottom: 1rem;
`;

export const BulkUpdateCheckboxesHorizontalContainer = styled.div`
  display: flex;
  justify-content: start;
  margin-top: 1rem;
  flex-wrap: wrap;
`;
export const BulkUpdateCheckboxesVerticalContainer = styled.div`
  display: flex;
  justify-content: start;
  flex-flow: column wrap;
  max-height: 150px;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  margin-top: 1rem;
  color: ${props => props.theme.checkbox.labelColor};
  font-size: 0.75rem;
`;

export const BulkUpdateCheckboxHorizontalWrapper = styled(CheckboxWrapper)`
  flex-flow: column;
  align-items: center;
  width: 60px;
  text-align: center;
`;

export const BulkUpdateCheckboxVerticalWrapper = styled(CheckboxWrapper)`
  display: flex;
  flex-flow: row;
`;

export const WidgetWrapper = styled.div`
  margin-top: 0.625rem;
  padding: 31px 35px 36px 31px;
  border-radius: 0.875rem;
  border: solid 1px ${(props): string => props.theme.colors["background-blue"]};
  background-image: linear-gradient(217deg, ${(props): string => props.theme.colors["night-blue"]} 74%, #07254d);
  color: ${(props): string => props.theme.colors.slate};
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const SpacedRow = styled(Row)`
  justify-content: space-between;
`;

export const FlexRow = styled.div`
  display: flex;
  > div:not(:last-child),
  button:not(:last-child) {
    margin-right: 0.5rem;
  }
`;

export const ButtonNavGroup = styled(FlexRow)`
  display: flex;

  > button {
    font-weight: bold;
    font-style: italic;
    font-size: 1rem !important;
  }
`;

export const Title = styled.div`
  color: ${(props): string => props.theme.label.color};
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 0.625rem;
`;

type HighlightType = "red" | "orange";

export const HighlightTitle = styled.div<{ color: HighlightType }>`
  color: ${(props): string => props.theme.colors.midnight};
  font-style: italic;
  font-size: 0.875rem;
  font-weight: 700;
  margin-bottom: 0.625rem;
  background: ${(props): string => {
    return props.color === "red" ? props.theme.colors["mlb-red"] : props.theme.colors["orange"];
  }};
  width: fit-content;
  padding: 8px;
  border-radius: 10px;
`;

export const EmailContainer = styled.div`
  background-color: ${(props): string => props.theme.colors.midnight};
  color: ${(props): string => props.theme.colors.fog};
  font-size: 0.875rem;
  padding: 0.75rem;
  overflow: scroll;
  max-height: inherit;
`;

export const PlainText = styled.text`
  font-size: 14px;
  color: ${(props): string => props.theme.colors.fog};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const SlLink = styled(Link)`
  color: ${(props): string => props.theme.colors["orange"]} !important;
`;
