import styled from "styled-components";
import { CheckboxThemeType } from "../../../types";

type CheckboxStylesProps = {
  styles: CheckboxThemeType;
  disabled?: boolean;
  readonly?: boolean;
};

export const CheckboxContainer = styled.div<CheckboxStylesProps>`
  display: flex;
  align-items: center;
  position: relative;

  &:hover {
    cursor: ${(props) => (!props.disabled ? "pointer" : "not-allowed")} !important;
  }
`;

export const CheckboxLabel = styled.label<CheckboxStylesProps>`
  margin: ${(props) => props.styles.margin};
  font-size: 0.875rem;
  color: ${(props) => props.styles.labelColor};
  font-weight: bold;
  display: inline;
  clear: both;

  &:before,
  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: ${(props) => (!!props.styles.top ? props.styles.top : "5px")};
  }

  &:before {
    // box
    width: 0.875rem;
    height: 0.875rem;
    background: ${(props) => (props.disabled ? props.styles.disabledColor : props.styles.background)};
    border: 1px solid ${(props) => props.styles.borderColor};
    border-radius: 4px;
    cursor: ${(props) => (props.disabled || props.readonly ? "not-allowed" : "pointer")} !important;
    transition: background 0.3s;
  }

  &:hover {
    cursor: ${(props) => (props.disabled || props.readonly ? "not-allowed" : "pointer")} !important;
  }
`;

export const CheckboxInput = styled.input.attrs(() => ({
  type: "checkbox",
}))<CheckboxStylesProps>`
  outline: 0;
  visibility: hidden;
  width: 0.875rem;
  height: 0.875rem;
  margin: ${(props) => props.style?.margin};
  display: block;
  float: left;
  font-size: inherit;

  &:checked {
    + label:before {
      background: ${(props) => props.styles.background};

      &:hover {
        cursor: ${(props) => (!props.disabled ? "pointer" : "not-allowed")} !important;
      }
    }
    + label:after {
      transform: translate(0.0625rem, 0.15625rem) rotate(-45deg);
      width: 0.7rem;
      height: 0.4rem;
      border: 0.15625rem solid ${(props) => props.styles.color};
      border-top-style: none;
      border-right-style: none;

      &:hover {
        cursor: ${(props) => (!props.disabled ? "pointer" : "not-allowed")} !important;
      }
    }
  }

  &:disabled {
    + label:after {
      border-color: ${(props) => props.styles.disabledColor};

      &:hover {
        cursor: ${(props) => (!props.disabled ? "pointer" : "not-allowed")} !important;
      }
    }
    &:checked {
      + label:before {
        background: ${(props) => props.styles.disabledBackgroundColor} !important;

        &:hover {
          cursor: ${(props) => (!props.disabled ? "pointer" : "not-allowed")} !important;
        }
      }
    }
  }

  &:hover {
    cursor: ${(props) => (!props.disabled ? "pointer" : "not-allowed")} !important;
  }
`;
