export type PopoverPlacement =
  | "top"
  | "top-start"
  | "top-end"
  | "right"
  | "right-start"
  | "right-end"
  | "bottom"
  | "bottom-start"
  | "bottom-end"
  | "left"
  | "left-start"
  | "left-end";

export type PopoverStrategy = "absolute" | "fixed";

export type TriggerTypes = "hover" | "click";

export const Triggers = {
  HOVER: "hover" as TriggerTypes,
  CLICK: "click" as TriggerTypes,
};
