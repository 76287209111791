import React from "react";
import { useTheme } from "../../../hooks";
import { ModalFooterThemeType } from "../../../types";
import { deepMerge } from "../../../utils";
import { GenericModal, ModalFooterProps } from "../../modal";

const PopoverFooter: React.FC<React.PropsWithChildren<ModalFooterProps>> = ({ children, styles = {}, ...rest }) => {
  const { Theme } = useTheme();
  const StylesOverride: ModalFooterThemeType = deepMerge<ModalFooterThemeType>(Theme.popover.footer, styles);

  return (
    <GenericModal.Footer styles={StylesOverride} {...rest}>
      {children}
    </GenericModal.Footer>
  );
};

PopoverFooter.displayName = "ModalFooter";

export default PopoverFooter;
