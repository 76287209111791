import React from "react";
import styled from "styled-components";
import { useCamera } from "../../../../contexts/CameraContext";
import { GameSettingDTO } from "../../../../types/CamTypes";
import { FlexRow } from "../../../common/CommonStyledComponents";
import { DashboardWidgetWrapper, LeftLabelledRowItem, Title, TableValue } from "../../DashboardStyledComponents";

const BroadcastInfoRow = styled(FlexRow)`
  justify-content: space-between;
`;
const BroadcastInformation: React.FC = () => {
  const { cameraState } = useCamera();

  const selectedGamePk: number = cameraState?.selectedGame?.gamePk;

  if (!selectedGamePk) return null;

  return (
    <DashboardWidgetWrapper style={{ width: "225px" }}>
      <FlexRow>
        <Title style={{ width: "100px" }}>Broadcast</Title>
        <Title style={{ width: "125px", marginLeft: "0.5rem" }}>Network</Title>
      </FlexRow>
      <BroadcastInfoRow>
        <LeftLabelledRowItem label="Home" labelWidth={80} valueWidth={125}>
          <TableValue>{cameraState?.selectedGame?.homeRsn}</TableValue>
        </LeftLabelledRowItem>
      </BroadcastInfoRow>
      <BroadcastInfoRow>
        <LeftLabelledRowItem label="Visit" labelWidth={80} valueWidth={125}>
          <TableValue>{cameraState?.selectedGame?.awayRsn}</TableValue>
        </LeftLabelledRowItem>
      </BroadcastInfoRow>
      <BroadcastInfoRow>
        <LeftLabelledRowItem label="National" labelWidth={80} valueWidth={125}>
          <TableValue>{cameraState?.selectedGame?.nationalRsn}</TableValue>
        </LeftLabelledRowItem>
      </BroadcastInfoRow>
    </DashboardWidgetWrapper>
  );
};

export default BroadcastInformation;
