import { DataTableColumn, FormatterType, ValueOpt } from "best-common-react";
import React from "react";
import { saveGameCamera, updateGameCameraInput } from "../../../../api/GameSettingApi";
import {
  primitivesToOptions,
  lookupsToOptions,
  lookupToOption,
  primitiveToOption,
} from "../../../../contexts/DropdownContext";
import { useCamera } from "../../../../contexts/CameraContext";
import { GameCameraInput } from "../../../../types/CamTypes";
import { FlexRow } from "../../../common/CommonStyledComponents";
import DashboardTable from "../../common/DashboardTable";
import { DashboardWidgetWrapperNoGradient, Title } from "../../DashboardStyledComponents";
import { AngleSelect, getFeedOptions } from "../../common/AngleSelect";
import { DashboardConstants } from "../../../../constants/DashboardConstants";

const FIRST_COLUMN_INPUTS = 9;
const SECOND_COLUMN_INPUTS = 8;
const SECOND_COLUMN_EXPANDED_INPUTS = 7;

const CameraInputs: React.FC = () => {
  const { cameraState, dispatchCamera } = useCamera();
  const { selectedGameForm } = cameraState;
  const { gameCameraInputs } = selectedGameForm;
  const locked = selectedGameForm?.gameSetting?.sfLock;

  const getForm = (sequence: number): GameCameraInput => {
    return gameCameraInputs?.find((x: GameCameraInput): boolean => sequence === x.sequence);
  };

  const angleOptions: ValueOpt<any>[] = lookupsToOptions(cameraState.angles);
  const angleModOptions: ValueOpt<any>[] = primitivesToOptions(cameraState.angleModifications);
  const offsetOptions: ValueOpt<any>[] = primitivesToOptions(cameraState.offsets);
  const feedOptions: ValueOpt<any>[] = primitivesToOptions(cameraState.feeds);

  const gamePk: number = cameraState?.selectedGame?.gamePk;

  const leftRows = [...Array(FIRST_COLUMN_INPUTS).keys()].map((i: number) => {
    return {
      label: "ISO",
      sequence: i + 1,
    };
  });

  const rightRows = [...Array(SECOND_COLUMN_INPUTS + SECOND_COLUMN_EXPANDED_INPUTS).keys()].map((i: number) => {
    return {
      label: "ISO",
      sequence: FIRST_COLUMN_INPUTS + i + 1,
    };
  });

  const columns: DataTableColumn<any>[] = [
    {
      name: "Camera Angle",
      key: "field",
      width: 210,
      editFormatter: (props: FormatterType<any>) => (
        <AngleSelect
          {...props}
          dashboard={DashboardConstants.CAM.value}
          type="gameCameraInputs"
          field="angle"
          width={210}
          gamePk={gamePk}
          form={getForm(props.row.sequence)}
          dispatch={dispatchCamera}
          options={angleOptions}
          getValue={lookupToOption}
          saveApi={saveGameCamera}
          updateApi={updateGameCameraInput}
          locked={locked}
        />
      ),
    },
    {
      name: "Angle Modif",
      key: "angleModif",
      width: 180,
      editFormatter: (props: FormatterType<any>) => (
        <AngleSelect
          {...props}
          dashboard={DashboardConstants.CAM.value}
          type="gameCameraInputs"
          field="angleModification"
          width={180}
          gamePk={gamePk}
          form={getForm(props.row.sequence)}
          dispatch={dispatchCamera}
          options={angleModOptions}
          getValue={primitiveToOption}
          saveApi={saveGameCamera}
          updateApi={updateGameCameraInput}
          locked={locked}
        />
      ),
    },
    {
      name: "Offset",
      key: "angleOffset",
      width: 120,
      editFormatter: (props: FormatterType<any>) => (
        <AngleSelect
          {...props}
          dashboard={DashboardConstants.CAM.value}
          type="gameCameraInputs"
          field="angleOffset"
          width={120}
          gamePk={gamePk}
          form={getForm(props.row.sequence)}
          dispatch={dispatchCamera}
          options={offsetOptions}
          getValue={primitiveToOption}
          saveApi={saveGameCamera}
          updateApi={updateGameCameraInput}
          locked={locked}
        />
      ),
    },
    {
      name: "Feed",
      key: "feed",
      width: 150,
      editFormatter: (props: FormatterType<any>) => (
        <AngleSelect
          {...props}
          dashboard={DashboardConstants.CAM.value}
          type="gameCameraInputs"
          field="feed"
          width={150}
          gamePk={gamePk}
          form={getForm(props.row.sequence)}
          dispatch={dispatchCamera}
          options={getFeedOptions(feedOptions, getForm(props?.row?.sequence)?.angle?.name)}
          getValue={primitiveToOption}
          saveApi={saveGameCamera}
          updateApi={updateGameCameraInput}
          locked={locked}
        />
      ),
    },
  ];
  return (
    <DashboardWidgetWrapperNoGradient>
      <>
        <Title>Inputs</Title>
      </>
      <FlexRow>
        <div style={{ marginRight: "1rem" }}>
          <DashboardTable label="ISO" data={leftRows} columns={columns} />
        </div>
        <div>
          <DashboardTable
            label="ISO"
            data={rightRows}
            columns={columns}
            rowsVisible={SECOND_COLUMN_INPUTS}
            showToggle
          />
        </div>
      </FlexRow>
    </DashboardWidgetWrapperNoGradient>
  );
};

export default CameraInputs;
