export const Categories = {
  CALL_ON_THE_FIELD: "Call On the Field",
  PLAY_PRIMARY: "Play Primary",
  RULING_AFTER_REVIEW: "Ruling After Review",
};

export const ChallengeTypes = {
  MC: "MC",
  CCR: "CCR",
};

export const CategoryIds = {
  CALL_ON_THE_FIELD: 1,
  PLAY_PRIMARY: 2,
  RULING_AFTER_REVIEW: 3,
};

export const SubCategoryIds = {
  SUB_1: 1,
  SUB_2: 2,
  SUB_3: 3,
};

export const SubCategories = {
  SUB_1: "Sub Category1",
  SUB_2: "Sub Category2",
  SUB_3: "Sub Category3",
};

export const ReviewStatuses = {
  COMPLETED: "COMPLETED",
  OPEN: "OPEN",
};
