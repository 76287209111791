import clsx from "clsx";
import React from "react";
import { useTheme } from "../../hooks";
import { TypographyElementStyles, TypographyStyles } from "../../types";
import { NestedPartial, deepMerge } from "../../utils";
import {
  TypographyDiv,
  TypographyH1,
  TypographyH2,
  TypographyH3,
  TypographyH4,
  TypographyH5,
  TypographyH6,
  TypographyParagraph,
  TypographySpan,
} from "./TypographyStyles";

export type OverridableTypographyElementStyles = {
  size?: number | string;
  color?: string;
  weight?: string | number;
  style?: string;
  width?: string | number;
  fontFamily?: string;
};

type TypographyProps = React.HTMLAttributes<any> & {
  /** type of typography */
  variant?:
    | "div"
    | "span"
    | "body1"
    | "body2"
    | "body3"
    | "caption"
    | "subtitle2"
    | "subtitle1"
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "h6";
  /** className to pass to text component */
  className?: string;
  /** styles to override */
  styles?: NestedPartial<TypographyStyles>;
  /** element styles */
  elementStyles?: OverridableTypographyElementStyles;
};

/**
 * Text component to display formatted, styled text in an application
 */
const Typography = ({
  variant = "body1",
  className = "",
  children,
  styles = {},
  elementStyles = {},
  ...rest
}: TypographyProps) => {
  const { Theme } = useTheme();
  const StylesOverride: TypographyStyles = deepMerge<TypographyStyles>(Theme.typography, styles ?? {});

  const getStyles = (): TypographyElementStyles => {
    return { ...StylesOverride[variant], ...elementStyles };
  };

  switch (variant) {
    case "h1":
      return (
        <TypographyH1 className={clsx(className)} styles={getStyles()} {...rest}>
          {children}
        </TypographyH1>
      );
    case "h2":
      return (
        <TypographyH2 className={clsx(className)} styles={getStyles()} {...rest}>
          {children}
        </TypographyH2>
      );
    case "h3":
      return (
        <TypographyH3 className={clsx(className)} styles={getStyles()} {...rest}>
          {children}
        </TypographyH3>
      );
    case "h4":
      return (
        <TypographyH4 className={clsx(className)} styles={getStyles()} {...rest}>
          {children}
        </TypographyH4>
      );
    case "h5":
      return (
        <TypographyH5 className={clsx(className)} styles={getStyles()} {...rest}>
          {children}
        </TypographyH5>
      );
    case "h6":
      return (
        <TypographyH6 className={clsx(className)} styles={getStyles()} {...rest}>
          {children}
        </TypographyH6>
      );
    case "div":
      return (
        <TypographyDiv className={clsx(className)} styles={getStyles()} {...rest}>
          {children}
        </TypographyDiv>
      );
    case "span":
      return (
        <TypographySpan className={clsx(className)} styles={getStyles()} {...rest}>
          {children}
        </TypographySpan>
      );
    case "body1":
    case "body2":
    case "body3":
    case "caption":
    case "subtitle1":
    case "subtitle2":
    default:
      return (
        <TypographyParagraph className={clsx(className)} styles={getStyles()} {...rest}>
          {children}
        </TypographyParagraph>
      );
  }
};

export default Typography;
