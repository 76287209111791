import styled from "styled-components";
import { DatatableThemeType } from "../../types";

interface PaginationStyleProps {
  $styles: DatatableThemeType;
}
export const PageSizeHolder = styled.div.attrs(() => ({
  className: "ms-2",
}))`
  width: 7.5rem;
`;

export const PaginationContainer = styled.div<PaginationStyleProps>`
  &&& {
    .page-link {
      background: ${(props) => props.$styles.pagination.background} !important;
      color: ${(props) => props.$styles.pagination.color} !important;
    }
    .page-item.active .page-link {
      background: ${(props) => props.$styles.pagination.active.background} !important;
      color: ${(props) => props.$styles.pagination.active.color} !important;
    }
    .page-item.disabled .page-link {
      background: ${(props) => props.$styles.pagination.disabled.background} !important;
      color: ${(props) => props.$styles.pagination.disabled.color} !important;
    }
  }
`;
