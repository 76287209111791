import { Textarea } from "best-common-react";
import React, { useEffect, useState } from "react";
import { DashboardWidgetWrapper, Title } from "../../DashboardStyledComponents";
import styled from "styled-components";
import { CameraAction, useCamera } from "../../../../contexts/CameraContext";
import { getPatch } from "../../../common/Patch";
import { saveGameSetting, updateGameSetting } from "../../../../api/GameSettingApi";
import { GameSetting } from "../../../../types/CamTypes";

const StyledTextArea = styled(Textarea)`
  width: 400px;
`;

const Notes: React.FC = () => {
  const { cameraState, dispatchCamera } = useCamera();
  const [value, setValue] = useState<string>("");

  const save = () => {
    if (cameraState?.selectedGameForm?.gameSetting?.id) {
      updateGameSetting(
        cameraState.selectedGameForm.gameSetting.id,
        getPatch(Boolean(cameraState.selectedGameForm?.gameSetting?.sfNotes), "/sfNotes", value),
      ).then(dispatch);
    } else {
      saveGameSetting({ gamePk: cameraState.selectedGame?.gamePk, sfNotes: value }).then(dispatch);
    }
  };

  const dispatch = (response: GameSetting) => {
    dispatchCamera({
      type: CameraAction.UPDATE_NOTES,
      payload: response,
    });
  };

  useEffect(() => {
    const notes: string = cameraState.selectedGameForm?.gameSetting?.sfNotes;
    setValue(notes ?? "");
  }, [cameraState.selectedGameForm.gameSetting]);

  return (
    <DashboardWidgetWrapper>
      <>
        <Title>Notes</Title>
      </>
      <StyledTextArea
        id="seriesNotes"
        value={value}
        onChange={(value: string) => setValue(value)}
        onBlur={() => save()}
      />
    </DashboardWidgetWrapper>
  );
};

export default Notes;
