import React, { forwardRef, ForwardRefExoticComponent, PropsWithoutRef, RefAttributes } from "react";
import { useTheme } from "../../../hooks";
import { ModalThemeType } from "../../../types";
import { deepMerge, NestedPartial } from "../../../utils";
import { ModalBodyProps, ModalFooterProps, ModalHeaderProps } from "../../modal";
import GenericModal from "../../modal/genericModal/GenericModal";
import GenericPopover, { GenericPopoverProps, PopoverRef } from "../generic/GenericPopover";
import PopoverBody from "./PopoverBody";
import PopoverFooter from "./PopoverFooter";
import PopoverHeader from "./PopoverHeader";

export interface PopoverProps extends GenericPopoverProps {
  children: React.ReactElement[] | React.ReactElement;
  styles?: NestedPartial<ModalThemeType>;
}

//allow undefined at construction
export interface PopoverComponentSubType
  extends ForwardRefExoticComponent<PropsWithoutRef<PopoverProps> & RefAttributes<PopoverRef>> {
  Header?: React.FC<React.PropsWithChildren<ModalHeaderProps>>;
  Body?: React.FC<React.PropsWithChildren<ModalBodyProps>>;
  Footer?: React.FC<React.PropsWithChildren<ModalFooterProps>>;
}

//force them to be defined when exported
export interface PopoverComponentType extends PopoverComponentSubType {
  Header: React.FC<React.PropsWithChildren<ModalHeaderProps>>;
  Body: React.FC<React.PropsWithChildren<ModalBodyProps>>;
  Footer: React.FC<React.PropsWithChildren<ModalFooterProps>>;
}

const PopoverSub: PopoverComponentSubType = forwardRef<PopoverRef, PopoverProps>(
  ({ children, styles = {}, ...rest }, ref) => {
    const { Theme } = useTheme();
    const StylesOverride: ModalThemeType = deepMerge<ModalThemeType>(Theme.popover, styles);
    return (
      <GenericPopover element={ref as React.RefObject<PopoverRef>} {...rest}>
        <GenericModal styles={StylesOverride}>{children}</GenericModal>
      </GenericPopover>
    );
  },
);

PopoverSub.Header = PopoverHeader;
PopoverSub.Body = PopoverBody;
PopoverSub.Footer = PopoverFooter;

const Popover = PopoverSub as PopoverComponentType;

export default Popover;
