import { ValueOpt } from "best-common-react";
import _ from "lodash";
import {
  AllDailyAssignmentDTO,
  DailyPositionAssignment,
  GameDataDTO,
  ReplayOfficialAssignmentByGameDTO,
  ReplayOfficialAssignmentDTO,
} from "../../types/DailyAssignmentTypes";
import { LookupData, Team } from "../../types/LookupTypes";
import { PersonDTO, Umpire } from "../../types/PersonTypes";
import {
  Angle,
  LookupAssociation,
  PlayCategoryValue,
  PlayType,
  Setting,
  SpecialLinkPlayType,
  Tech,
  User,
} from "../../types/SettingsTypes";
import { GameTech } from "../../types/CamTypes";
import { Person } from "../../types/StatsApiTypes";
import { VideoCoach } from "../../types/SupportTypes";
import { getGameInfo } from "../dashboards/camera/game-view/CameraView";

export const NOT_APPLICABLE_ID = -1;
export const CCR_ID = -2;
export const NOT_APPLICABLE_OPTION: ValueOpt<any> = { label: "N/A", value: null, id: NOT_APPLICABLE_ID };
export const CCR_OPTION: ValueOpt<any> = { label: "CCR", value: { orgId: CCR_ID, code: "CCR" }, id: CCR_ID };

const getNameLastFirst = (user: User): string => {
  if (user === null || user === undefined) return "";
  return `${user.lastName}, ${user.firstName}`;
};

export const getFormattedName = (name: string): string => {
  if (name === null || name === undefined) return null;
  const idx = name.indexOf(" ");
  const firstName = name.substring(0, idx);
  const lastName = name.substring(idx + 1, name.length);
  return lastName + ", " + firstName;
};

const uniqById = (options: ValueOpt<any>[]): ValueOpt<any>[] => {
  return _.uniqBy(options, o => o?.id);
};

export const getReplayOfficialOption = (r: ReplayOfficialAssignmentByGameDTO): ValueOpt<any> => {
  if (!r) return null;
  return { label: getFormattedName(r.name), id: r.umpire?.personId, value: r.umpire?.personId, data: r };
};
export const getReplayOfficialOptions = (rs: ReplayOfficialAssignmentByGameDTO[]): ValueOpt<any>[] => {
  if (!rs) return null;
  return uniqById(rs.map(getReplayOfficialOption));
};
export const getReplayOfficialAssignmentOption = (r: ReplayOfficialAssignmentDTO): ValueOpt<any> => {
  if (!r) return null;
  return getPersonDTOOption(r.umpire);
};
export const getReplayOfficialAssignmentOptions = (rs: ReplayOfficialAssignmentDTO[]): ValueOpt<any>[] => {
  if (!rs) return null;
  return uniqById(rs.map(getReplayOfficialAssignmentOption));
};
export const getStationOption = (num: number): ValueOpt<any> => {
  return { label: `${num}`, id: num, value: num, data: num };
};
export const getUserOption = (u: User): ValueOpt<any> => {
  if (!u) return null;
  return { label: getNameLastFirst(u), value: u, data: u, id: u.id };
};
export const getUserOptions = (us: User[]): ValueOpt<any>[] => {
  if (!us) return null;
  return uniqById(us.map(getUserOption));
};
export const getReplayOperatorOption = (u: User): ValueOpt<any> => {
  if (!u) return null;
  return { label: getNameLastFirst(u), id: u.id, value: u, data: u };
};
export const getUmpireOption = (u: Umpire): ValueOpt<any> => {
  if (!u) return null;
  return { label: getFormattedName(u.name), id: u.personId, value: u.personId, data: u };
};
export const getPersonDTOOption = (p: PersonDTO): ValueOpt<any> => {
  if (!p) return null;
  return {
    label: (p.personId ?? -1) > -1 ? getFormattedName(p.name) : p.name,
    id: p.personId,
    value: p.personId,
    data: p,
  };
};
export const getStatsPersonOption = (p: Person): ValueOpt<any> => {
  if (!p) return null;
  return { label: getFormattedName(p.fullName), id: p.id, value: p.id, data: p };
};
export const getPlayTypeOption = (playType: PlayType): ValueOpt<any> => {
  if (!playType) return null;
  return { label: playType.name, id: playType.id, value: playType, data: playType };
};

export const getOptionFromPropName = (object: any, propName: string): ValueOpt<any> => {
  if (!object || !object[propName]) return null;
  return { label: object[propName].name, id: object[propName].id, value: object[propName].id };
};

export const getCopyOption = (game: GameDataDTO): ValueOpt<number> => {
  if (!game) return null;
  return {
    // TODO: BCR should likely accept elements here.  This is functional but reports a type error
    label: getGameInfo(game),
    value: game.gamePk,
    data: game,
    selected: false, // used for checkbox
  };
};
export const getTechOption = (tech: Tech): ValueOpt<any> => {
  if (!tech) return null;
  return { label: `${tech.lastName}, ${tech.name}`, value: tech.id, data: tech, id: tech.id };
};
export const getGameTechOption = (tech: GameTech | Partial<GameTech>): ValueOpt<any> => {
  if (!tech) return null;
  return { label: `${tech.tech?.lastName}, ${tech.tech?.name}`, value: tech.id, data: tech, id: tech.id };
};
export const getAngleOption = (angle: Angle): ValueOpt<Angle> => {
  if (!angle) return null;
  return { label: angle.name, value: angle, data: angle, id: angle.id };
};

export const isValueOpt = (x: any): x is ValueOpt<any> =>
  x !== null && x !== undefined && x.hasOwnProperty("id") && x.hasOwnProperty("value") && x.hasOwnProperty("label");

export const getAllDailyAssignmentStaffOptionsSupportChecks = (allDaily: AllDailyAssignmentDTO): ValueOpt<any>[] => {
  let list: ValueOpt<any>[] = [];
  allDaily?.allPositions?.forEach(position => {
    list = list.concat([
      getUserOption(position.dayUser),
      getUserOption(position.nightUser),
      getUserOption(position.lateNightUser),
    ]);
  });
  list = list.filter((x: ValueOpt<any>): boolean => !!x);
  return uniqById(
    list
      .concat(allDaily?.allReplayOperator?.map(getUserOption))
      .concat(allDaily?.allReplayOperatorAsst?.map(getUserOption)),
  );
};

export const getPlayCategoryValueOption = (pcv: PlayCategoryValue): ValueOpt<PlayCategoryValue> => {
  if (!pcv) return null;
  return { label: pcv.name, id: pcv.id, value: pcv, data: pcv };
};

export const getPlayCategoryValueOptions = (pcvs: PlayCategoryValue[]): ValueOpt<any>[] => {
  if (!pcvs) return null;
  return uniqById(pcvs.map(getPlayCategoryValueOption));
};

export const getTeamOption = (team: Team): ValueOpt<any> => {
  if (!team) return null;
  return { label: team.abbreviation, id: team.id, value: team, data: team };
};

export const getChallengingTeamOption = (team: Team): ValueOpt<any> => {
  if (!team) return null;
  else if (CCR_OPTION.id === team.id) return CCR_OPTION;
  return { label: team.abbreviation, id: team.id, value: team, data: team };
};

export const getVideoCoachOption = (videoCoach: VideoCoach): ValueOpt<VideoCoach> => {
  if (!videoCoach) return null;
  return { id: videoCoach.id, label: videoCoach.fullName, value: videoCoach, data: videoCoach };
};

export const getSpecialLinkPlayTypeOption = (playType: SpecialLinkPlayType): ValueOpt<SpecialLinkPlayType> => {
  if (!playType) return null;
  return { id: playType.id, label: playType.name, value: playType, data: playType };
};

export const getSpecialLinkSubTypeOption = (subtype: LookupAssociation): ValueOpt<LookupAssociation> => {
  if (!subtype) return null;
  return { id: subtype.id, label: subtype.name, value: subtype, data: subtype };
};

export const getSettingFromOption = (opt: ValueOpt<any>, labelField?: string): Setting => {
  if (!opt) return null;
  return { id: opt.id, name: opt[labelField ?? "label"] };
};

export const primitiveToOption = (val: any): ValueOpt<any> => {
  if (val === null || val === undefined) return null;
  return { id: val, label: String(val), value: val, data: val };
};

// Take an array of primitives and get an array of valid dropdown options
export const primitivesToOptions = (arr: any[]): ValueOpt<any>[] => {
  if (!arr || arr.length === 0) return [];
  return arr.map(primitiveToOption).filter((x: ValueOpt<any>): boolean => !!x && x.value !== null);
};

export const numberToOption = (val: number): ValueOpt<any> => {
  return primitiveToOption(val);
};
export const stringToOption = (val: string): ValueOpt<any> => {
  return primitiveToOption(val);
};
export const booleanToOption = (val: boolean): ValueOpt<any> => {
  return primitiveToOption(val);
};

export const lookupToOption = (lookupData: LookupData, labelField?: string): ValueOpt<any> => {
  if (!lookupData) return null;
  return { label: lookupData[labelField || "name"], id: lookupData.id, value: lookupData, data: lookupData };
};

export const personToOption = (person: PersonDTO, labelField?: string): ValueOpt<any> => {
  if (!person) return null;
  return { label: person[labelField || "name"], id: person.personId, value: person.personId, data: person };
};

export const personsToOptions = (arr: PersonDTO[], labelField?: string): ValueOpt<any>[] => {
  if (!arr || arr.length === 0) return [];
  return arr
    .map((x: PersonDTO): ValueOpt<any> => personToOption(x, labelField))
    .filter((x: ValueOpt<any>): boolean => !!x && x.value !== null);
};

export const settingToOption = (setting: Setting, labelField?: string): ValueOpt<any> => {
  if (!setting) return null;
  return { label: setting[labelField || "name"], id: setting.id, value: setting, data: setting };
};

export const settingsToOptions = (arr: Setting[], labelField?: string): ValueOpt<any>[] => {
  if (!arr || arr.length === 0) return [];
  return arr
    .map((x: Setting): ValueOpt<any> => settingToOption(x, labelField))
    .filter((x: ValueOpt<any>): boolean => !!x && x.value !== null);
};

export const convertSelectedToPerson = (selected: ValueOpt<any>) => {
  const person: PersonDTO = { name: selected.label.toString(), personId: selected.value };
  return person;
};

export const lookupsToOptions = (arr: LookupData[], labelField?: string): ValueOpt<any>[] => {
  if (!arr || arr.length === 0) return [];
  return arr
    .map((x: LookupData): ValueOpt<any> => lookupToOption(x, labelField))
    .filter((x: ValueOpt<any>): boolean => !!x && x.value !== null);
};

export const getStaffOptions = (staffAssignments: DailyPositionAssignment[], position: string): ValueOpt<any>[] => {
  const options: ValueOpt<any>[] = [];
  if (!staffAssignments || !position) {
    return options;
  }
  staffAssignments
    .filter(x => x.actualPosition.includes(position))
    .forEach(s => {
      ["dayUser", "nightUser", "lateNightUser"].forEach(property => {
        const user: User = s[property];
        if (user) {
          options.push(getUserOption(user));
        }
      });
    });
  return uniqById(options);
};

const INSTANT_REPLAY_START_YEAR = 2008;
export const YEAR_OPTIONS: ValueOpt<any>[] = [
  ...Array(new Date().getFullYear() - INSTANT_REPLAY_START_YEAR + 1).keys(),
].map(year => {
  return {
    label: String(year + INSTANT_REPLAY_START_YEAR),
    value: year + INSTANT_REPLAY_START_YEAR,
    id: year + INSTANT_REPLAY_START_YEAR,
  };
});

export const getBooleanOptions = (): ValueOpt<any>[] => {
  return [
    {
      value: true,
      label: "Yes",
    },
    {
      value: false,
      label: "No",
    },
  ];
};
