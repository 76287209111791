export const GameTechConstants = {
  COMPOUND_1: "Compound 1",
  COMPOUND_2: "Compound 2",
  COMPOUND_3: "Compound 3",
  COMPOUND_4: "Compound 4",
  COMPOUND_5: "Compound 5",
  HEADSET: "Headset",
};

export const getGameTechTypes = (isPostSeason: boolean): string[] => {
  if (isPostSeason) {
    return [
      GameTechConstants.COMPOUND_1,
      GameTechConstants.COMPOUND_2,
      GameTechConstants.COMPOUND_3,
      GameTechConstants.COMPOUND_4,
      GameTechConstants.COMPOUND_5,
      GameTechConstants.HEADSET,
    ];
  } else {
    return [
      GameTechConstants.COMPOUND_1,
      GameTechConstants.COMPOUND_2,
      GameTechConstants.COMPOUND_3,
      GameTechConstants.HEADSET,
    ];
  }
};
