import React, { forwardRef, ForwardRefExoticComponent, PropsWithoutRef, RefAttributes } from "react";
import { useTheme } from "../../../hooks";
import { deepMerge, NestedPartial } from "../../../utils";
import GenericPopover, { GenericPopoverProps, PopoverRef } from "../generic/GenericPopover";
import { TooltipContainer, TooltipStyles } from "./TooltipStyles";

export interface TooltipProps extends GenericPopoverProps {
  styles?: NestedPartial<TooltipStyles>;
}

type TooltipType = ForwardRefExoticComponent<PropsWithoutRef<TooltipProps> & RefAttributes<PopoverRef>>;

const Tooltip: TooltipType = forwardRef<PopoverRef, TooltipProps>(({ children, styles = {}, ...rest }, ref) => {
  const { Theme } = useTheme();
  const StylesOverride: TooltipStyles = deepMerge<TooltipStyles>(Theme.tooltip, styles);
  return (
    <GenericPopover
      element={ref as React.RefObject<PopoverRef>}
      showArrow={true}
      arrowColor={StylesOverride.background}
      {...rest}
    >
      <TooltipContainer styles={StylesOverride}>{children}</TooltipContainer>
    </GenericPopover>
  );
});

export default Tooltip;
